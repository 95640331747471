.box-body.with-tabs {
  display: flex;
  padding: 0;

  // Tabs
  ul {
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul a {
    display: block;
    padding: 5px 10px;
    border-right: 1px solid grey;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  ul li.active a {
    background-color: #ffffff;
    border-right-color: transparent;
  }

  // Panes
  .tab-content {
    flex: 1;
    background: #ffffff;
  }

  .tab-pane {
    padding: 10px;
  }

  .chart-container {
    height: 100%;
  }
}

.box-body.with-list-and-chart {
  display: flex;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
  }

  > .chart-container {
    flex: 1;
    min-height: 350px;
  }
}

.box-body.with-number-and-chart {
  display: flex;
  align-items: center;

  .number-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }

  .chart-container {
    flex: 1;
    height: 100%;
    margin-left: 10px;
  }
}

.statistics-tile {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}