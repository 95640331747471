.widgets-wrapper {
  .panel {
    display: flex;
    flex-direction: column;
  }

  .panel-heading {
    border-radius: 0;
    background-color: $color-blue;
    color: $color-white;
  }

  .panel-body {
    flex: 1;
    // overflow: auto;

    &.no-padding {
      padding: 0;
    }
  }

  .panel-footer {

  }
}