@keyframes card-loading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.card {
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  &.loading {
    &::before,
    &::after {
      position: absolute;
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.5);
      content: '';
    }

    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: 'Font Awesome 5 Free';
      font-size: 50px;
      font-weight: 900;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      content: '\f110';
      animation: card-loading 2s infinite linear;
    }
  }

  h3 {
    margin-top: 0;
  }
}