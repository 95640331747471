// Develop > API > Console
.develop.api.console {
  .tabs-list {
    display: flex;
    flex-wrap: wrap;

    > li {
      float: none;
    }

    > li > button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    > li:not(:last-child) > button {
      margin-right: 2px;
    }
  }

  .filters-container {
    padding-top: 15px;
    background-color: $color-white;
  }

    .errors-container {
        margin-bottom: 15px;
        background-color: $color-white;

        .response-success {
            border-left: 2px solid transparent;
            border-left-color: #5ec938;
        }

        .response-error {
            border-left: 2px solid transparent;
            border-left-color: #f33636;
        }
    }

  .table-container ul {
    padding-left: 20px;
  }

  .console-container {
    border: solid #dddddd;
    border-width: 0 1px 1px 1px;

    tbody .device-uid {
      font-family: $font-console;
    }

    .even {
      background-color: #f9f9f9;
    }

    tr[data-toggle="row"] {
      cursor: pointer;
    }

    .date-time {
      text-align: center;
    }

    .action {
      max-width: 800px;
    }

    .os {
      white-space: nowrap;
    }

    .result {
      text-align: center;
    }

    .date-time,
    .full-uri,
    .details-container {
      border-left: 2px solid transparent;
    }

    .full-uri {
      padding: 8px 15px;
      font-family: $font-console;
      word-break: break-all;
      word-break: break-word;
    }

    .details-container {
      padding: 8px 0;
    }

    .response-success {
      .date-time {
        border-left-color: #5ec938;
      }

      .full-uri,
      .details-container {
        border-left-color: rgba(#5ec938, .5);
      }

      &.response-slow .date-time {
        border-left-color: #f9c039;
      }

      &.response-slow .full-uri,
      &.response-slow .details-container {
        border-left-color: rgba(#f9c039, .5);
      }
    }

    .response-error {
      .date-time {
        border-left-color: #f33636;
      }

      .full-uri,
      .details-container {
        border-left-color: rgba(#f33636, .5);
      }

      &.response-slow {
        border-left-color: #180712;
      }

      &.response-slow .full-uri,
      &.response-slow .details-container {
        border-left-color: rgba(#180712, .5);
      }
    }

    .block-scrollable {
      max-height: 340px;
      overflow-y: auto;
      white-space: pre-wrap;
      word-break: break-all;
    }

    .logs-container {
      color: #333333;
      font-size: 13px;
      font-family: $font-console;
    }

    .level-notice {
      color: $color-yellow;
    }

    .level-warning {
      color: #ee8012;
    }

    .level-error,
    .level-critical,
    .level-alert,
    .level-emergency {
      color: $color-tomato-red;
    }

    a[data-action="copy"] {
      cursor: pointer;
    }

    .table {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
      color: $color-light-blue;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    h4 {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      small {
        font-size: 90%;
      }
    }
  }
}

// Develop > API > Doc and Run
.develop.api.doc-and-run {
  .swagger-ui {
    .wrapper {
      max-width: none;
      padding: 0;
    }

    // Top bar
    .topbar {
      padding: 5px;
      background: $color-darker-blue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .topbar .topbar-wrapper {
      flex-wrap: wrap;
    }

    .topbar a,
    .topbar .download-url-wrapper {
      flex: 0 0 100%;
      max-width: initial;
    }

    .topbar .download-url-wrapper {
      margin-left: auto;
    }

    .topbar .download-url-wrapper .select-label {
      flex-direction: column;
    }

    .topbar .download-url-wrapper .select-label span {
      margin-bottom: 5px;
      padding: 0;
    }

    //
    .information-container {
      padding: 0 5px;
      background: $color-darker-blue;
    }

    .info {
      margin: 0;
    }

    .info .title {
      display: none;
      color: #f0f0f0;
    }

    .info .title small pre {
      background: none;
      border: none;
      font-family: $font-console;
    }

    .info hgroup.main {
      margin: 0;
    }

    .info .description {
      margin: 0;
    }

    //
    .scheme-container {
      padding: 0 5px 5px 5px;
      background: $color-darker-blue;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: none;
      color: #f0f0f0;
    }

    .scheme-container .schemes > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .servers-title {
      margin-bottom: 5px;
      text-align: center;
    }

    .servers > label {
      width: 100%;
      margin: 0;
    }

    .servers > label select {
      width: 100%;
    }

    @media (min-width: $screen-sm-min) {
      .topbar a,
      .topbar .download-url-wrapper {
        flex: initial;
      }

      .topbar a {
        max-width: 300px;
      }

      .topbar .download-url-wrapper {
        max-width: 600px;
      }

      //
      .information-container {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      //
      .scheme-container .schemes > div {
        flex-direction: row;
      }

      .servers-title {
        margin-right: 5px;
        margin-bottom: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      .topbar .download-url-wrapper .select-label {
        flex-direction: row;
      }

      .topbar .download-url-wrapper .select-label span {
        margin-right: 5px;
        margin-bottom: 0;
      }

      &.swagger-container > .swagger-ui > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
      }

      .information-container,
      .scheme-container {
        width: 50%;
        margin-bottom: 15px;
      }

      .information-container {
        display: flex;
        align-items: center;
        margin: 0 0 15px 0;
        padding-bottom: 5px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0;
      }

      .scheme-container {
        padding-top: 15px;
        border-bottom-left-radius: 0;
      }

      .scheme-container .schemes > div {
        justify-content: flex-end;
      }
    }
  }
}

// Develop > API > Cache
.develop-api-cache {
  .tiles-container {
    .loading {
      background-color: $color-dark-blue;
    }

    .selected {
      background-color: $color-blue;
    }
  }

  #form-rules {
    .table-indexed {
      counter-reset: rules;

      tr:not(.row-second) td:first-of-type {
        text-align: center;

        &::before {
          counter-increment: rules;
          content: counter(rules);
        }
      }
    }

    .row-first {
      td a {
        display: inline-block;
        width: 23px;
        height: 23px;
      }
    }

    .headers-list {
      margin-bottom: 15px;

      .input-group + .input-group {
        margin-top: 15px;
      }
    }
  }
}

// Develop > API > Security
.develop-api-security {
  .domains-container {
    margin-top: 7px;
  }

  #form-rules tbody {
    counter-reset: rules;

    tr td:first-of-type {
      text-align: center;

      &::before {
        counter-increment: rules;
        content: counter(rules);
      }
    }
  }
}

// Develop > API > Environment
.develop.api.environment {
  // Diagnostics
  .diagnostic-passed,
  .diagnostic-missing,
  .diagnostic-failed {
    font-weight: bold;
  }

  .diagnostic-passed {
    color: $color-mint-green;
  }

  .diagnostic-missing {
    color: #edd000;
  }

  .diagnostic-failed {
    color: $color-tomato-red;
  }

  thead .end + .start {
    border-left: 1px solid #ddd;
  }

  tr[data-file] .end + .start,
  tr[data-table] .end + .start {
    border-left: 1px solid #f3f3f3;
  }

  tr[data-file].has-diff td,
  tr[data-table].has-diff td {
    cursor: pointer;

    &:last-child .fas {
      transition: transform 0.3s ease;
    }
  }

  tr[data-file].diff-shown td:last-child .fas,
  tr[data-table].diff-shown td:last-child .fas {
    transform: rotate(90deg);
  }

  tr[data-of-table] > td {
    padding: 0;
  }

  tr[data-of-table] table {
    margin-bottom: 0;
    border-top: none;
  }

  tr[data-of-table] table tr:last-child td {
    border-bottom: none;
  }

  tr[data-of-table] th,
  tr[data-of-table] td,
  tr[data-file-diff-of] td {
    background-color: #ededed;
    border-color: #dddddd;
    font-size: 13px;
  }

  tr[data-of-table] .end + .start {
    border-left: 1px solid #dddddd;
  }

  .diff-wrapper {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;

    .old,
    .new {
      font-family: "Courier New", sans-serif;
      white-space: pre-wrap;
    }

    .n-old,
    .n-new {
      padding: 1px 5px;
      text-align: right;
    }

    ins {
      text-decoration: none;
    }

    .change-eq + .change-eq {
      border-top: 1px dashed $color-dark-grey;
    }

    // Present on both sides but with different values
    .change-rep {
      .old {
        background: #fe9;
      }

      .new {
        background: #fd8;
      }

      ins,
      del {
        background: #fc0;
      }

      del {
        text-decoration: none;
      }
    }

    // Not present on the left side (.change-ins)
    .change-ins {
      .old {
        //background: #cfc;
        background: #f88;
      }

      .new {
        //background: #dfd;
        background: #faa;
      }

      ins,
      del {
        //background: #9e9;
        background: #e99;
      }
    }


    // Not present on the right side (.change-del)
    .change-del {
      .old {
        background: #faa;
      }

      .new {
        background: #f88;
      }

      ins,
      del {
        background: #e99;
      }
    }
  }
}

// Develop > Data > Files
.develop-data-files {
  .files-header {
    display: flex;
    margin-bottom: 15px;

    > * + * {
      margin-left: 15px;
    }
  }

  .files-container {
    &.as-list {
      .header, .loader, .folder, .file {
        display: flex;
        align-items: center;
        min-height: 40px;
      }

      .folder + .folder,
      .folder + .file,
      .file + .file {
        border-top: 1px solid #f3f3f3;
      }

      // Fake a table header
      .header {
        background-color: #f3f3f3;
        color: #666666;
        font-size: 14px;
        font-weight: bold;
      }

      .icon,
      .name,
      .size,
      .last-modified-at,
      .permissions,
      .actions {
        display: inline-flex;
        align-items: center;
        height: inherit;
        padding: 5px 5px;
      }

      .icon {
        justify-content: center;
        width: 40px;
        font-size: 20px;
      }

      .name {
        flex: 1;
      }

      .size {
        width: 100px;
      }

      .last-modified-at {
        width: 180px;
      }

      .permissions {
        width: 100px;
      }

      .actions {
        justify-content: center;
        width: 70px;

        a + a {
          margin-left: 5px;
        }

        .fa, .fas, .fab {
          font-size: 20px;
        }
      }

      progress {
        width: 450px;
      }
    }
  }
}

// Develop > Data > Text manager
.develop.data.text-manager {
  #wrapper {
    padding-bottom: 70px;
  }

  .search-container {
    flex: 1;
    max-width: 300px;
  }

  .texts-container {
    > tbody > tr > td {
      vertical-align: top;
    }

    > tfoot > tr > th {
      border-top-color: #f3f3f3;
    }

    input,
    textarea {
      min-width: 250px;
    }

    .new-key {
      background-color: $color-info;
    }

    .duplicated-key {
      background-color: $color-warning;
    }

    .conflict {
      background-color: $color-danger;
    }
  }

  .language-container {
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }

    a {
      font-size: 16px;
    }

    a:first-of-type {
      margin-left: auto;
    }

    a ~ a {
      margin-left: 10px;
    }
  }

  table.dataTable {
    margin: 0 !important;
  }

  .btn-floating.btn-primary {
    right: 70px;
    bottom: 15px;
  }

  .btn-floating.btn-success {
    right: 15px;
    bottom: 15px;
  }

  #modal-import-csv .table {
    margin: 0;
  }
}

// Develop > SDK > Download
.develop.sdk.download {
  .tiles-container {
    display: flex;
    flex-wrap: wrap;
    margin: -7.5px;
  }

  .tile {
    width: 100%;
    min-height: 245px;
    margin: 7.5px;
    padding: 15px;
    border-radius: 5px;
    color: $color-white;
    text-align: center;

    &.tile-android {
      background-color: $color-android;
    }

    &.tile-ios {
      background-color: $color-ios;
    }

    &.tile-windows-phone {
      background-color: $color-windows-phone;
    }
  }

  .tile-image {
    margin-bottom: 15px;

    .fas,
    .far,
    .fab {
      width: 57px;
      height: 57px;

      &::before {
        font-size: 57px;
      }
    }
  }

  .tile-focus {
    font-family: $font-roboto-slab;
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
  }

  .tile-text {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    line-height: 17px;
  }

  .tile a {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  @media (min-width: $screen-md-min) {
    .tile {
      width: calc((100% - 45px) / 3);
    }
  }
}

// Develop > SDK > Settings
.develop.sdk.settings {
  .control-label {
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: bold;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"] {
    height: 32px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(102, 102, 102, 0.06);
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $color-dark-grey;
    font-size: 12px;
  }

  .well {
    font-size: 12px;

    .control-label {
      text-transform: uppercase;
    }
  }

  .panel {
    background-color: #f6f6f6;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }

  .panel-heading {
    display: flex;
    align-items: center;
    padding: 20px 18px;
    color: #666666;
    font-size: 12px;

    label {
      margin: 0 25px 0 25px;
      font-size: 12px;
      font-weight: normal;
    }

    button {
      margin-left: auto;
    }
  }

  .panel-title {
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .panel-toggle {
    width: 23px;
    height: 23px;
    padding: 0;
    background-color: $color-darker-blue;
    border: none;
    border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
  }

  .panel-body {
    background-color: rgba(0, 0, 0, 0.04);

    .form-group:last-child {
      margin-bottom: 0;
    }

    .switchable-wrapper {
      padding-top: 9px;
    }
  }
}
