// Security > Register
// Security > Password forgotten
.security {
  h1, .h1,
  h2, .h2 {
    text-transform: uppercase;
  }

  h1, .h1 {
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 20px;
    overflow: hidden;

    &::after {
      display: inline-block;
      flex: 1;
      height: 1px;
      margin-left: 30px;
      background-color: rgba(255, 255, 255, 0.14);
      content: '';
    }
  }

  h2, .h2 {
    font-size: 16px;
  }

  section + section {
    margin-top: 40px;
  }

  .help-block {
    margin-top: 5px;
  }

  #error-container:not(:empty) {
    margin: 10px 0;
  }

  .buttons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .open-id-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -10px;

    .btn-open-id {
      width: 100%;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: $screen-sm-max) {
    .row .col-md-6 + .col-md-6 {
      margin-top: 10px;
    }
  }

  @media (min-width: $screen-xs-min) {
    .open-id-container .btn-open-id {
      width: calc((100% - 30px) / 3);
    }
  }

  @supports (display: grid) {
    .open-id-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      margin-left: 0;
      margin-right: 0;

      .btn-open-id {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
      }

      @media (min-width: $screen-xs-min) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &.password-reset h1 + p {
    margin-top: 20px;
  }
}