.jGrowl-notification {
  &.success {
    background-color: $color-success;
  }

  &.info{
    background-color: $color-info;
  }

  &.warning {
    background-color: $color-yellow;
  }

  &.danger {
    background-color: $color-danger;
  }
}