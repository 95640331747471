// Dashboard
.dashboard {
  .dashboard-container {
    display: flex;
    min-height: calc(100vh - 180px);

    strong {
      font-size: 30px;
    }
  }

  .health-container {
    .health {
      display: inline-block;
      height: 10px;
    }

    $colors: #ff0000 #ff1515 #ff3434 #e36c1c #ff6700 #ff7c25 #d3b505 #9ba300 #00a800 #00ff00;

    @for $i from 1 through length($colors) {
      .health[data-rating="#{$i}"] {
        background: nth($colors, $i);
      }
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 30px repeat(auto-fit, minmax(10px, 1fr));
      grid-column-gap: 5px;
      align-items: center;
    }
  }

  @media (min-width: $screen-sm-min) {
    .dashboard-grid {
      display: grid;
      grid-gap: 15px;

      .card {
        margin-bottom: 0;
      }

      &.type-a {
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "chart chart chart chart chart chart chart chart chart chart chart chart" "health health health health stores stores stores stores feedback feedback feedback feedback" "session-times session-times session-times sessions-per-user sessions-per-user sessions-per-user downloads-evolution downloads-evolution downloads-evolution ratings-evolution ratings-evolution ratings-evolution";
      }

      &.type-b {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "chart chart chart" "health session-times sessions-per-user";

        .card[data-card="stores"],
        .card[data-card="feedback"],
        .card[data-card="downloads-evolution"],
        .card[data-card="ratings-evolution"] {
          display: none;
        }
      }

      &.type-c {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas: "chart chart chart chart chart chart" "health health stores stores feedback feedback" "session-times session-times session-times sessions-per-user sessions-per-user sessions-per-user";

        .card[data-card="downloads-evolution"],
        .card[data-card="ratings-evolution"] {
          display: none;
        }
      }

      &.type-d {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "chart chart" "health health" "session-times sessions-per-user";

        .card[data-card="stores"],
        .card[data-card="feedback"],
        .card[data-card="downloads-evolution"],
        .card[data-card="ratings-evolution"] {
          display: none;
        }
      }
    }

    @each $card in chart, health, stores, feedback, session-times, sessions-per-user, downloads-evolution, ratings-evolution {
      .card[data-card="#{$card}"] {
        grid-area: #{$card};
      }
    }
  }

  .statistics-container {
    flex: 1;
  }

  .activity-stream {
    width: 350px;
    margin: -20px -20px -50px 20px;
    padding: 15px;
    background-color: #eeeeee;

    .card {
      display: grid;
      grid-column-gap: 15px;
      grid-template-columns: 40px 1fr;
      -ms-grid-columns: 40px 15px 1fr;
      grid-template-rows: auto auto;
      -ms-grid-rows: 1fr 1fr;

      &:last-child {
        margin-bottom: 0;
      }
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-row: span 2;
      -ms-grid-row-span: 2;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      font-size: 20px;
    }

    .meta {
      align-self: end;
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    .text {
      align-self: start;
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }
  }

  @media (min-width: $screen-md-min) {
    .main-footer {
      right: 365px;
    }
  }
}