.navmenu-sidebar {
  top: 58px;
  width: 230px;
  // https://github.com/jasny/bootstrap/issues/462#issuecomment-320465378
  // But margins also need to be deleted
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: 9px 0 11px -11px rgba(0, 0, 0, 0.32);

  // https://github.com/jasny/bootstrap/issues/230#issuecomment-72788763
  .dropdown-menu {
    position: relative;
  }

  // Icons and blanks
  .fas, .fab, .far, .fa,
  .blank {
    margin-right: 15px;
  }

  .blank {
    width: 1.25em;
  }

  // Normal state for top items
  .nav > li > a {
    display: flex;
    align-items: center;
    height: 50px;
    color: $color-light-grey;
    font-size: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  // Normal state for nested items
  .dropdown-menu > li > a {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: calc(1.25em + 30px);
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  // Hover and focus states for top items
  .nav > li > a:focus,
  .nav > li > a:hover {
    background-color: #f1f1f1;
    color: $color-dark-grey;
    font-weight: 600;
  }
  // Hover and focus states for nested items
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {
    background-color: #f1f1f1;
  }

  // Open state for top items
  .nav .open > a,
  .nav .open > a:focus,
  .nav .open > a:hover {
    background-color: #fbfbfb;
    color: $color-light-blue;
    font-weight: 600;
  }

  // Open state for nested items
  .nav .open .dropdown-menu > li > a {
  }

  // Active state for top items
  .nav > li.active > a {
    background-color: #fbfbfb;
    color: $color-light-blue;
    font-weight: 600;
  }

  // Active state for nested items
  .nav > li.active .dropdown-menu > li.active a {
    background-color: $color-light-blue;
    color: #ffffff;
  }
}