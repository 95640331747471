.logged-out {
  padding-bottom: 137px;
  background: url('/assets/images/backgrounds/logged-out.jpg') no-repeat;
  background-size: cover;
  color: #ffffff;
  font-family: $font-roboto-regular;

  // https://la-cascade.io/video-en-background/
  #background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -100;
    min-width: 100%;
    width: auto;
    min-height: 100%;
    height: auto;
  }

  .main-navbar {
    height: 67px;
    background-color: rgba(15, 36, 61, 0.32);
    box-shadow: 0 2px 3px 0 rgba(3, 3, 3, 0.19);
    color: #ffffff;

    .navbar-toggle {
      margin-top: 16.5px;
      margin-bottom: 16.5px;

      .icon-bar {
        background-color: #ffffff;
      }
    }

    .navbar-brand {
      height: 67px;
      padding-top: 18.5px;
      padding-bottom: 18.5px;

      img {
        width: auto;
        max-height: 30px;
      }
    }

    .nav > li > a {
      padding-top: 23.5px;
      padding-bottom: 23.5px;
      color: inherit;
      font-size: 14px;
      font-family: $font-roboto-regular;
      font-weight: bold;
      text-transform: uppercase;

      &:focus, &:hover {
        background: none;
      }
    }

    @media (max-width: $screen-xs-max) {
      .navbar-collapse {
        background-color: rgba(15, 36, 61, 0.32);
        border-bottom: 1px solid rgba(15, 36, 61, 0.32);
      }
    }
  }

  .main-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 117px;
    background-color: #0f243d;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .logo-on-top {
    @include image-set('/assets/images/logos/apps-panel-with-text', '.png');
    padding-top: 130px;
    background-repeat: no-repeat;
    background-position: top center;
  }

  .modal {
    color: #333;
  }

  a {
    color: inherit;
  }

  .has-error .help-block[data-temporary="true"],
  .form-errors {
    margin-top: 10px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 1px solid #a94442;
  }

  @media (max-width: $screen-xs-max) {
    padding-bottom: 80px;

    .main-footer {
      height: 70px;
    }
  }
}
