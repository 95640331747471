.drag-handle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  @include image-set('/assets/images/ui/dragdrop', '.png');

  &:hover {
    cursor: grab;
  }
}

.sortable-handle {
  font-size: 20px;

  &:hover {
    cursor: grab;
  }
}
