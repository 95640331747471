/**
 * This component is to be used with the Highcharts library.
 *
 * @see https://www.highcharts.com/
 */
.graph-container {
  margin-top: 40px;

  .highcharts-tooltip {
    font-family: $font-roboto-regular;
    font-size: 20px;
    text-transform: uppercase;
  }
}