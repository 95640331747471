.tiles-container {
  .tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 245px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: $color-light-blue-alt;
    border-radius: 5px;
    color: $color-white;
    text-align: center;

    &.bg-orange {
      background-color: #FBB03B;
    }

    &.bg-green {
      background-color: #6AAF09;
    }

    &.bg-teal {
      background-color: #10B28D;
    }

    &.bg-dark-teal {
      background-color: #55B477;
    }

    &.bg-blue {
      background-color: #0c94d2;
    }

    &.selectable{
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
        cursor: pointer;
      }
    }

    .tile-header {
      max-height: 100px;
      margin-bottom: 30px;
      padding-bottom: 12px;
      border-bottom: 1px solid #fff;
      font-size: 15px;
      text-transform: uppercase;
      line-height: 16px;
    }

    .tile-focus {
      font-family: $font-roboto-slab;
      font-size: 40px;
      font-weight: bold;
      word-break: break-all;
      word-break: break-word;
    }

    .tile-text {
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      line-height: 17px;
      word-break: break-all;
      word-break: break-word;
    }

    .tile-image {
      margin-bottom: 9px;

      .fa,
      .fas,
      .far,
      .fab {
        width: 57px;
        height: 57px;

        &::before {
          font-size: 57px;
        }
      }
    }

    a {
      color: inherit;

      &:hover, &:focus {
        color: inherit;
      }
    }
  }
}
