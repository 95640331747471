/**
 * Bootstrap 3
 */
.help-block {
  margin-top: 0;
  margin-bottom: 0;
}

.control-label {
  font-weight: 700;

  &.required::after {
    content: ' *';
    font-size: 13px;
  }
}

div.form-control {
  min-height: 34px;
  height: auto;
}

// Fix line-height for Firefox
// https://bugzilla.mozilla.org/show_bug.cgi?id=1503427#c14
input[type="date"].form-control, input[type="time"].form-control,
input[type="datetime-local"].form-control, input[type="month"].form-control {
  line-height: initial;
}


// Make textarea look like other inputs
textarea {
  min-height: 34px;
  resize: vertical;
}

/**
 * Transparent forms
 *
 * @see http://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete/32505530#answer-37432260
 */
@keyframes autofill-icon-email {
  to {
    background-repeat: no-repeat;
    background-position: left 15px center;
    background-color: rgba(255, 255, 255, 0.08);
    @include image-set('/assets/images/ui/icon-email', '.png');
    color: #ffffff;
  }
}

@keyframes autofill-icon-lock {
  to {
    background-repeat: no-repeat;
    background-position: left 15px center;
    background-color: rgba(255, 255, 255, 0.08);
    @include image-set('/assets/images/ui/icon-lock', '.png');
    color: #ffffff;
  }
}

.form-transparent {
  input[type="text"],
  input[type="password"],
  input[type="email"] {
    width: 100%;
    height: 43px;
    padding-left: 58px;
    padding-right: 23px;
    border: none;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: left 15px center;
    background-color: rgba(255, 255, 255, 0.08);
    color: #ffffff;
    font-family: $font-roboto-regular;
    font-size: 14px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.54);
      font-style: italic;
      opacity: 0.54;
    }

    &.icon-user {
      @include image-set('/assets/images/ui/icon-user', '.png');
    }

    &.icon-company {
      @include image-set('/assets/images/ui/icon-company', '.png');
    }

    &.icon-phone {
      @include image-set('/assets/images/ui/icon-phone', '.png');
    }

    &.icon-lock {
      @include image-set('/assets/images/ui/icon-lock', '.png');
    }

    &.icon-email {
      @include image-set('/assets/images/ui/icon-email', '.png');
    }
  }

  .icon-email:-webkit-autofill {
    -webkit-animation-name: autofill-icon-email;
    -webkit-animation-fill-mode: both;
  }

  .icon-lock:-webkit-autofill {
    -webkit-animation-name: autofill-icon-lock;
    -webkit-animation-fill-mode: both;
  }

  .row + .row {
    margin-top: 10px;
  }
}

/**
 * Custom <select>
 */
@supports (appareance: none) or (-moz-appearance: none) or (-webkit-appearance: none) {
  select.select {
    display: inline-block;
    height: 37px;
    padding: 0 20px 0 6px;
    @include image-set('/assets/images/ui/caret-grey', '.png');
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ccc;
    appearance: none;
    color: rgba(102, 102, 102, 0.6);
    font-family: $font-open-sans;
    font-weight: normal;

    &:hover,
    &:focus {
      @include image-set('/assets/images/ui/caret-grey-hover', '.png');
      border-color: #66afe9;
    }

    &:focus {
      outline: none;
    }

    // So as to have a darker font color when an option with an actual value is selected, JavaScript is needed
    // to add a class
    &.not-empty {
      color: $color-dark-grey;
    }

    option {
      // display: inline-block;
      // height: 37px;
      // appearance: none;
      color: $color-dark-grey;
      font-family: $font-open-sans;
      font-size: 14px;
      // font-weight: bold;
    }
  }
}

/**
 * Lists
 */
.form-container {
  .form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -5px -5px 10px -5px;

    > * {
      margin: 5px;
    }

    // Center switchable vertically
    .switchable-holder {
      margin: 3px 5px 0 0;
    }

    .spacer { // @deprecated Use margin-(left|right): auto
      flex: 1;
    }
  }

  .table-responsive,
  table {
    margin-bottom: 0;
  }

  table {
    thead th,
    thead td {
      vertical-align: middle;
    }

    tbody {
      tr.is-checked th,
      tr.is-checked td {
        background-color: $color-light-blue;
        color: $color-white;

        a {
          color: $color-white;
        }
      }
    }
  }

  .table-actions {
    .btn {
      @include button-variant($color-light-blue);
      padding: 0 15px;
      border-radius: 0;
      font-size: 14px;
      font-weight: 600;
      text-transform: none;

      &:hover, &:focus {
        color: $color-white;
      }

      + .btn {
        border-left: 1px solid $color-white;
      }
    }

    @media (min-width: $screen-sm-min) {
      position: absolute;
      left: 0;
      bottom: 15px;
      z-index: 10;
    }
  }

  // Columns
  .column-checkbox {
    width: 30px;
    text-align: center;

    .checkbox-container {
      margin-bottom: 0;
      line-height: 1;

      input[type="checkbox"] {
        display: none;
      }

      .checkbox-widget {
        display: inline-block;
        width: 15px;
        height: 14px;
        @include image-set('/assets/images/ui/forms/checkbox-off', '.png');
        background-repeat: no-repeat;
        background-position: center center;
      }

      input[type="checkbox"]:checked + .checkbox-widget {
        @include image-set('/assets/images/ui/forms/checkbox-on', '.png');
      }
    }
  }
}

/**
 * Modal forms
 */
.form-modal {
  // Widgets and constrols
  .form-control-static {
    min-height: auto;
    padding-bottom: 0;
  }
}

/**
 * Select 2
 */
.select2-container--default {
  .select2-selection--single,
  .select2-selection--multiple {
    min-height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    color: #555;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  .select2-selection--single {
    .select2-selection__rendered {
      line-height: 34px;
    }

    .select2-selection__arrow {
      top: 4px;
    }
  }

  &.select2-container--open .select2-selection--single,
  &.select2-container--focus .select2-selection--multiple {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  }

  .select2-search--dropdown .select2-search__field {
    border-color: #ccc;
  }
}

.select2-dropdown {
  border-color: #ccc;
}

// Fix input groups with Select2
.input-group {
  .form-control:first-child + .select2-container {
    .select2-selection {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    + .input-group-btn > .btn {
      margin-left: 0;
    }
  }

  .input-group-btn + .form-control + .select2-container .select2-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group-btn button {
    height: 34px;
    box-shadow: none;
  }
}

.select2-search-result {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .image-container {
    width: 60px;

    img {
      width: auto;
      max-width: 60px;
      height: auto;
      max-height: 60px;
    }
  }

  .text-container {
    flex: 1;
    max-height: 100px;
    padding-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Lists */
.input-list:not(:empty) {
  margin-bottom: 15px;
}

/* Custom inputs */
.input-group-flex {
  .input-group-addon {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: auto;
  }

  .input-group-btn {
    flex: 0 0 auto;
    width: auto;
  }

  .btn {
    min-height: 34px;
    height: 100%;
  }

  label.input-group-addon {
    margin-bottom: 0;
  }

  @media (max-width: $screen-xxs-max) {
    .form-control + .form-control {
      margin-top: 5px;
    }
  }

  @media (min-width: $screen-xs-min) {
    display: flex;

    > :first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > :not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    > :last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    // Select2
    .select2-hidden-accessible:first-child + .select2-container:not(:last-child) .select2-selection {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) .select2-selection {
      border-radius: 0;
    }

    .select2-hidden-accessible:not(:first-child) + .select2-container:last-child .select2-selection  {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

/* Errors */
.form-errors {
  margin: 0;
  color: #a94442;
}
