// Modal sizes
@media (min-width: $screen-lg-min) {
  .modal-xl {
    width: 1000px;
  }
}

.modal-fill {
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}

.modal-full-screen {
  width: calc(100% - 60px);

  .modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
  }

  .modal-body {
    flex: 1;
    overflow-y: auto;
  }
}

.modal {
  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: none;

    .close {
      order: 1; // Force close button to be after the title
      float: none;
      font-size: 30px;
      color: $color-white;
      opacity: 1;
      filter: none;

      &:focus,
      &:hover {
        opacity: 1;
        filter: none;
        text-shadow: 0 0 2px #ddd;
      }
    }
  }

  .modal-title {
    flex: 1;
    font-family: $font-open-sans;
    font-size: 20px;
  }

  .modal-sub-title {
    margin: 0;
    padding: 10px 15px;
    color: $color-white;
    font-size: 18px;
  }

  .modal-steps {
    background-color: $color-light-blue;
    font-family: $font-open-sans;

    ul {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 20px 25px 25px 25px;
      overflow-x: auto;

      li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::after {
          position: absolute;
          top: calc(100% + 5px);
          display: inline-block;
          width: 9px;
          height: 9px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 50%;
          content: '';
        }
      }

      li + li {
        margin-left: 15px;
      }
    }

    .step-number {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .step-name {
      font-size: 12px;
    }

    // Step states
    .step-active {
      color: $color-white;

      &::after {
        background-color: $color-white;
      }
    }

    .step-done {
      color: $color-blue;

      &::after {
        border-color: $color-blue;
        background-color: $color-blue;
      }
    }

    .step-pending {
      color: rgba(255, 255, 255, 0.2);
    }

    // Errors
    .has-error,
    .step-done.has-error {
      &::after {
        border-color: $color-tomato-red;
        background-color: $color-tomato-red;
      }
    }
  }

  // Combine modals and tabs
  .modal-tabs {
    .nav-tabs > li > a {
      border-radius: 0;
    }
  }

  .modal-body {
    h4 {
      margin-top: 0;
    }
  }

  // Colors
  &.modal-primary, // @todo Use only type-primary from BootstrapDialog?
  &.type-primary {
    .modal-header {
      background-color: $color-blue;
    }

    .modal-title {
      color: $color-white;
    }

    .modal-sub-title {
      background-color: lighten($color-light-blue, 5%);
    }

    .modal-tabs {
      .nav-tabs {
        background-color: lighten($color-light-blue, 5%);

        > li:not(.active) > a {
          color: $color-white;
        }

        > li:not(.active) > a:hover {
          color: #555555;
        }
      }
    }

    .modal-body {
      .control-label {
        color: $color-darker-blue;
        text-transform: uppercase;
      }
    }
  }
}

.bootstrap-dialog {
  .modal-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .bootstrap-dialog-header {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .bootstrap-dialog-close-button {
    float: none;
    opacity: 1;
    order: 2;
  }

  .bootstrap-dialog-title {
    flex: 1;
  }
}