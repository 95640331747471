@mixin image-set($prefix, $suffix) {
  background-image: url('#{$prefix}#{$suffix}');
  background-image: image-set(
    url('#{$prefix}#{$suffix}') 1x,
    url('#{$prefix}@2x#{$suffix}') 2x,
    url('#{$prefix}@3x#{$suffix}') 3x
  );
}

@mixin button-variant($background) {
  background-color: $background;

  &:focus,
  &.focus {
    background-color: darken($background, 10%);
  }
  &:hover {
    background-color: darken($background, 10%);
  }
  &:active,
  &.active {
    background-color: darken($background, 10%);

    &:hover,
    &:focus,
    &.focus {
      background-color: darken($background, 17%);
    }
  }
  &:active,
  &.active {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
    }
  }
}

@mixin menu-icon($name) {
  @include image-set('/assets/images/ui/menu/menu-#{$name}', '.png');
}