.watch.crash-report {
  #button-refresh {
    margin-left: auto;
  }

  #table-crashes {
    .time {
      font-size: 16px;
      font-weight: bold;
    }

    .column-reason {
      max-width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h3 {
      margin-top: 0;
      color: #3b87e9;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    > tbody > tr.open > td {
      background-color: $color-light-blue;
      color: $color-white;
    }

    .open + tr {
      background-color: #f5f9fe;
    }
  }

  .dataTables_paginate {
    text-align: center;

    ul.pagination {
      margin: 15px 0;
    }
  }
}