@keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }

  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }

  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }

  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }

  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }

  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }

  76% {
    opacity: 0;
    transform: rotate(900deg);
  }

  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}

#loader {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: .80;

  .wrapper {
    position: relative;
    width: 78px;
    height: 78px;
    margin: auto;
    top: 200px;
    transform: translateY(-50%);
  }

  .wrapper .ball {
    position: absolute;
    width: 74px;
    height: 74px;
    opacity: 0;
    transform: rotate(225deg);
    animation: orbit 6.96s infinite;
  }

  .wrapper .ball .inner-ball {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgb(0, 0, 0);
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .wrapper #ball-1 {
    animation-delay: 1.52s;
  }

  .wrapper #ball-2 {
    animation-delay: 0.3s;
  }

  .wrapper #ball-3 {
    animation-delay: 0.61s;
  }

  .wrapper #ball-4 {
    animation-delay: 0.91s;
  }

  .wrapper #ball-5 {
    animation-delay: 1.22s;
  }
}
