.upload-interface {
  .breadcrumb > li + li::before {
    content: "»";
  }

  table.dataTable {
    margin-top: 0 !important;
  }

  .dataTables_paginate {
    text-align: center;

    ul.pagination {
      margin: 15px 0;
    }
  }

  .files-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;

    .drop-zone {
      padding: 50px;
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px dashed black;
      border-radius: 5px;
    }

    i {
      font-size: 40px;
    }

    p {
      margin-top: 25px;
    }
  }

  #progress-bar {
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1990;
    border-radius: 0;

    .progress-bar {
      padding: 0 5px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}