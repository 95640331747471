.table {
  background-color: $color-white;
  border: 1px solid #f3f3f3;
  border-radius: 3px;

  > thead > tr > th {
    background-color: #f3f3f3;
    border-bottom: none;
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: bold;
  }

  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    border-bottom: 1px solid #f3f3f3;
    border-top: 1px solid #f3f3f3;
    color: $color-dark-grey;
    vertical-align: middle;
  }

  &.table-striped-2-by-2 {
    tbody tr:nth-of-type(4n+1),
    tbody tr:nth-of-type(4n+2) {
      background-color: #f9f9f9;
    }
  }

  &.align-top {
    > tbody > tr > th,
    > tbody > tr > td {
      vertical-align: top;
    }
  }

  // Rows
  .row-hint th,
  .row-hint td {
    text-align: center;
  }

  // Columns
  .column-thumbnail {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;

    .img-thumbnail {
      max-width: 150px;
      max-height: 150px;
    }
  }

  .column-icon {
    width: 25px;
    max-width: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td.column-icon {
    text-align: center;
    font-size: 20px;
  }

  .column-position {
    width: 30px;
    text-align: center;
  }

  .column-actions {
    text-align: center;
    white-space: nowrap;

    .fa,
    .fas,
    .far,
    .fab {
      font-size: 20px;
    }

    > * ~ :not(.tooltip) {
      margin-left: 10px;
    }

    a:hover,
    a:focus {
      text-decoration: none;
    }
  }
}

