.logged-in {
  padding-top: 58px;
  background-color: $color-lighter-grey;
  font-family: $font-open-sans;

  .main-navbar {
    height: 58px;
    background-color: $color-blue;
    color: #ffffff;

    .navbar-toggle {
      margin-top: 12px;
      margin-bottom: 12px;

      .icon-bar {
        background-color: #ffffff;
      }
    }

    .navbar-brand {
      height: 58px;
      padding-top: 14px;
      padding-bottom: 14px;

      img {
        width: auto;
        max-height: 30px;
      }
    }

    .navbar-nav > li > a {
      height: 58px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: $color-dark-blue;

      &:focus, &:hover {
        background-color: #10253e;
      }
    }

    @media (max-width: $screen-xs-max) {
      .navbar-toggle {
        display: inline-flex;
        flex-direction: column;
      }

      .navbar-collapse {
        background-color: $color-blue;
      }

      .navbar-form {
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
      }
    }
  }

  // Applications selector or display
  .applications-display {
    min-width: 300px;
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: $color-light-blue-alt;
    text-transform: uppercase;

    img {
      width: auto;
      height: auto;
      max-width: 32px;
      max-height: 32px;
      border-radius: 50%;
      overflow: hidden;
    }

    .application-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 10px;
      line-height: 1;
    }

    img + .application-wrapper {
      margin-left: 5px;
    }

    .application-name {
      font-size: 13.7px;
      font-weight: 600;
    }

    .application-environment {
      font-size: 12px;
      font-weight: 300;
    }

    // Only one application is available
    &.navbar-text {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 8px;
      padding-left: 5px;
      padding-right: 15px;
    }

    // Multiple applications are available
    .select2-selection__clear {
      display: none;
    }

    .select2-selection__rendered {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding-left: 5px;
      padding-right: 23px;
      color: #ffffff;
    }

    .select2-selection__placeholder {
      margin-left: 5px;
      color: inherit;
    }

    .select2-selection__arrow {
      @include image-set('/assets/images/ui/caret-white', '.png');
      top: 7px;
      right: 3px;
      background-repeat: no-repeat;
      background-position: center center;

      b {
        display: none;
      }
    }

    &[aria-expanded="true"] .select2-selection__arrow {
      @include image-set('/assets/images/ui/caret-white-up', '.png');
    }
  }

  .select2-container--default.select2-container--open.select2-container--below {
    .select2-selection--single.applications-display,
    .select2-selection--multiple.applications-display {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .applications-dropdown {
    top: 20px;
    background-color: #0a1626;
    box-shadow: 1.5px 4.8px 5px 0 rgba(0, 0, 0, 0.22);
    border-radius: 4px;

    &::before {
      position: absolute;
      top: -26px;
      right: 75px;
      content: "\f0d8";
      font-family: "Font Awesome\ 5 Free";
      color: #0a1626;
      font-size: 30px;
      font-weight: 900;
    }

    .select2-search {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 54px;
      padding-left: 54px;
      padding-right: 15px;
      @include image-set('/assets/images/ui/icon-white-magnifier', '.png');
      background-repeat: no-repeat;
      background-position: left 20px center;

      input {
        background: none;
        border: solid rgba(255, 255, 255, 0.39);
        border-width: 0 0 1px 0;
        color: #ffffff;
        font-size: 16px;

        &::placeholder {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.35);
        }

        &:focus {
          outline: none;
        }
      }
    }

    .select2-results {
      background-color: #090f18;
      color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
    }

    .select2-results__option {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 54px;
      padding: 0 15px;
      border-top: 1px solid rgba(255, 255, 255, 0.09);

      &[aria-selected="true"] {
        background-color: transparent;
        color: #5995d9;
      }

      &--highlighted,
      &--highlighted[aria-selected="true"] {
        background-color: #091422;
        color: #5995d9;
      }
    }

    img {
      flex: 0 0 35px;
      width: 35px;
      height: 35px;
      object-fit: contain;
      margin-right: 15px;
      border-radius: 50%;
      overflow: hidden;
    }

    .application-name, .application-environment {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .application-name {
      font-weight: 600;
    }

    .application-environment {
      margin-left: 5px;
      font-weight: 300;
    }
  }

  @media (max-width: $screen-xs-max) {
    .applications-display {
      min-width: auto;
    }
  }

  // Profile
  .profile-container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .profile-account {
    min-width: 220px;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .profile-name {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
  }

  .profile-email {
    color: #ffffff;
  }

  .profile-name + .profile-email {
    color: rgba(255, 255, 255, 0.57);
    font-size: 12px;
  }

  .profile-logout a {
    width: 58px;
    background-repeat: no-repeat;
    background-position: center center;
    @include image-set('/assets/images/ui/icon-quit', '.png')
  }

  @media (max-width: $screen-xs-max) {
    .profile-container {
      display: flex;
    }

    .profile-account {
      flex: 1;
      min-width: auto;
    }
  }

  // Underneath the main navbar
  .main-container {
    min-height: calc(100vh - 58px);
  }

  //
  #wrapper {
    margin-left: 230px;
    padding: 20px 20px 50px 20px;
  }

  #tabs-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    background-color: $color-darker-blue;
    width: calc(100% + 40px);
    margin: -20px -20px 20px -20px;
    overflow-x: auto;

    .tab {
      display: inline-block;
      height: 52px;
      padding: 14px 30px;
      border-bottom: 6px solid $color-darker-blue;
      color: rgba(255, 255, 255, 0.65);
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;

      &.selected {
        border-bottom-color: $color-light-blue;
        color: #ffffff;
        font-weight: 700;
      }
    }

    a {
      color: inherit;

      &:hover,
      &:focus {
        border-bottom-color: $color-dark-blue;
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
      }
    }
  }

  .main-footer {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }

  @media (max-width: $screen-xs-max) {
    #wrapper {
      margin-left: 0;
      padding-bottom: 85px;
    }

    .main-footer {
      left: 15px;
      text-align: center;
    }
  }
}