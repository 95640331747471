//== Colors

$color-white: #ffffff;
$color-lighter-grey: #fbfbfb;
$color-light-grey: #aeaeae;
$color-grey: #999999;
$color-dark-grey: #666666;

$color-light-blue-alt: #3e8df3;
$color-light-blue: #3b87e9;
$color-blue: #2c65ad;
$color-dark-blue: #142f50;
$color-darker-blue: #0f243d;

$color-mint-green: #4bd16d;
$color-tomato-red: #f33636;
$color-yellow: #f0ad4e;

$color-android: #a4c639;
$color-ios: #9a9a9a;
$color-windows-phone: #0993d2;

// Extracted from Bootstrap
$color-primary: #337ab7;
$color-success: #5cb85c;
$color-info: #5bc0de;
$color-warning: #f0ad4e;
$color-danger: #d9534f;

//== Typography

$font-roboto-regular: "Roboto", Helvetica, sans-serif;
$font-roboto-slab: "Roboto Slab", Helvetica, sans-serif;
$font-open-sans: "Open Sans", Helvetica, sans-serif;
$font-console: Menlo, Monaco, Consolas, "Courier New", monospace;

//== Media queries breakpoints

$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$screen-minimums: (
    xs: $screen-xs-min,
    sm: $screen-sm-min,
    md: $screen-md-min,
    lg: $screen-lg-min,
);
$screen-maximums: (
    xxs: $screen-xxs-max,
    xs: $screen-xs-max,
    sm: $screen-sm-max,
    md: $screen-md-max,
);
