//=== Margins and paddings
@each $value in (top right bottom left) {
  .margin-#{$value}-auto {
    margin-#{$value}: auto !important;
  }
}

$types: (m: margin, p: padding);
$sizes: (auto: auto, 0: 0, 1: 5px, 2: 10px, 3: 15px, 4: 20px);
$directions: (t: top, r: right, b: bottom, l: left);

@each $a, $type in $types {
  @each $b, $size in $sizes {
    @if not (padding == $type and auto == $size) {
      .#{$a}-#{$b} {
        #{$type}: $size !important;
      }

      .#{$a}x-#{$b} {
        #{$type}-left: $size !important;
        #{$type}-right: $size !important;
      }

      .#{$a}y-#{$b} {
        #{$type}-top: $size !important;
        #{$type}-bottom: $size !important;
      }

      @each $c, $direction in $directions {
        .#{$a}#{$c}-#{$b} {
          #{$type}-#{$direction}: $size !important;
        }
      }
    }
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $a, $type in $types {
      @each $b, $size in $sizes {
        @if not (padding == $type and auto == $size) {
          .#{$a}-#{$screen}-#{$b} {
            #{$type}: $size !important;
          }

          .#{$a}x-#{$screen}-#{$b} {
            #{$type}-left: $size !important;
            #{$type}-right: $size !important;
          }

          .#{$a}y-#{$screen}-#{$b} {
            #{$type}-top: $size !important;
            #{$type}-bottom: $size !important;
          }

          @each $c, $direction in $directions {
            .#{$a}#{$c}-#{$screen}-#{$b} {
              #{$type}-#{$direction}: $size !important;
            }
          }
        }
      }
    }
  }
}

//=== Display
$displays: (none, inline, inline-block, block, flex, inline-flex);

@each $display in $displays {
  .d-#{$display} {
    display: $display !important;
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $display in $displays {
      .d-#{$screen}-#{$display} {
        display: $display !important;
      }
    }
  }
}

//=== Flex
// justify-content
$values: (normal, start, end, center, stretch, left, right, space-between, space-around, space-evenly);

@each $value in $values {
  .justify-content-#{$value} {
    @if start == $value or end == $value {
      justify-content: flex-#{$value} !important;
    } @else {
      justify-content: $value !important;
    }
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $value in $values {
      .justify-content-#{$screen}-#{$value} {
        @if start == $value or end == $value {
          justify-content: flex-#{$value} !important;
        } @else {
          justify-content: $value !important;
        }
      }
    }
  }
}

// align-items
$values: (normal, start, end, center, stretch);

@each $value in $values {
  .align-items-#{$value} {
    @if start == $value or end == $value {
      align-items: flex-#{$value} !important;
    } @else {
      align-items: $value !important;
    }
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $value in $values {
      .align-items-#{$screen}-#{$value} {
        @if start == $value or end == $value {
          align-items: flex-#{$value} !important;
        } @else {
          align-items: $value !important;
        }
      }
    }
  }
}

// flex-direction
$values: (row row-reversse column column-reverse);

@each $value in $values {
  .flex-direction-#{$value} {
    flex-direction: $value !important;
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $value in $values {
      .flex-direction-#{$screen}-#{$value} {
        flex-direction: $value !important;
      }
    }
  }
}

// flex-wrap
$values: (nowrap, wrap, wrap-reverse);

@each $value in $values {
  .flex-wrap-#{$value} {
    flex-wrap: $value !important;
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $value in $values {
      .flex-wrap-#{$screen}-#{$value} {
        flex-wrap: $value !important;
      }
    }
  }
}

// flex-grow / flex-shrink
$values: (0 1);

@each $value in $values {
  .flex-grow-#{$value} {
    flex-grow: $value !important;
  }

  .flex-shrink-#{$value} {
    flex-shrink: $value !important;
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $value in $values {
      .flex-grow-#{$screen}-#{$value} {
        flex-grow: $value !important;
      }

      .flex-shrink-#{$screen}-#{$value} {
        flex-shrink: $value !important;
      }
    }
  }
}

//=== Width
$values: (auto: auto, 0: 0, 25: 25%, 50: 50%, 75: 75%, 100: 100%);

@each $key, $value in $values {
  .w-#{$key} {
    width: $value !important;
  }

  .max-w-#{$key} {
    max-width: $value !important;
  }
}

@each $screen, $minimum in $screen-minimums {
  @media (min-width: $minimum) {
    @each $key, $value in $values {
      .w-#{$screen}-#{$key} {
        width: $value !important;
      }

      .max-w-#{$screen}-#{$key} {
        max-width: $value !important;
      }
    }
  }
}

//=== Colors
// OS
.text-android {
  color: $color-android;
}

.text-ios {
  color: $color-ios;
}

.text-windows-phone {
  color: $color-windows-phone;
}

.bg-android {
  background-color: $color-android;
}

.bg-ios {
  background-color: $color-ios;
}

.bg-windows-phone {
  background-color: $color-windows-phone;
}

//
.text-secondary {
  color: $color-light-blue;
}

.bg-secondary {
  background-color: $color-light-blue;
  color: $color-white;
}

@media (min-width: 768px) {
  .flex-md-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}