body {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
  color: $color-dark-grey;
  font-size: 24px;
}

h2, .h2 {
  color: $color-light-blue;
  font-size: 20px;
  font-weight: 600;
}

h3, .h3 {
  font-size: 16px;
  font-weight: 600;
}

.page-header {
  margin-top: 0;

  h1, .h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    color: $color-dark-grey;
    font-size: 14px;
  }

  &.with-addon {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title-container + * {
      margin-top: 5px;
    }

    @media (min-width: $screen-sm-min) {
      align-items: center;
      flex-direction: row;

      .title-container {
        margin-right: 15px;
      }

      .title-container + * {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.break-words {
  word-break: break-all;
}

.white-space-nowrap {
  white-space: nowrap;
}

//=== Font
// Weights
$weights: normal bold lighter bolder 100 200 300 400 500 600 700 800 900;

@each $weight in $weights {
  .font-weight-#{$weight} {
    font-weight: $weight;
  }
}

// Style
.font-italic {
  font-style: italic;
}

// Font families
.font-console {
  font-family: $font-console;
}
