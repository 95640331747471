@mixin fa-icon-rotate($degrees, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
  transform: rotate($degrees);
}

.fa-rotate-45 {
  @include fa-icon-rotate(45deg, 1);
}

:root {
  .fa-rotate-45 {
    filter: none;
  }
}
