// Understand
.understand {
  .filters-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px 10px -5px;

    > * {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    button[type="submit"] {
      margin-left: auto;
    }
  }

  .has-tooltip {
    border-bottom: 1px dotted;

    &.text-success {
      border-bottom-color: $color-success;
    }

    &.text-warning {
      border-bottom-color: $color-warning;
    }

    &.text-danger {
      border-bottom-color: $color-danger;
    }
  }

  // Internet Explorer and common
  .grid-container {
    display: -ms-grid;

    .card {
      margin: 0;
    }

    + .grid-container {
      margin-top: 15px;
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }

  &.analytics .grid-container {
    -ms-grid-columns: 100%;

    // Top container
    &.top-container {
      -ms-grid-rows: auto 15px auto;
    }

    [data-card="most-called"] {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    [data-card="total"] {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    // Pies container
    &.pies-container {
      -ms-grid-rows: auto 15px auto 15px auto 15px auto 15px auto 15px auto;
    }

    $cards: methods http-codes os cache-states response-times response-sizes;
    $i: 1;

    @each $card in $cards {
      [data-card="#{$card}"] {
        -ms-grid-column: 1;
        -ms-grid-row: $i;
      }

      $i: $i + 2;
    }

    @media (min-width: $screen-md-min) {
      // Top container
      &.top-container {
        -ms-grid-columns: 30% 15px calc(70% - 15px);
        -ms-grid-rows: auto;
      }

      [data-card="most-called"] {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      [data-card="total"] {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      // Pies container
      &.pies-container {
        -ms-grid-columns: calc((100% - 30px) / 3) 15px calc((100% - 30px) / 3) 15px calc((100% - 30px) / 3);
        -ms-grid-rows: auto 15px auto;
      }

      $i: 0;

      @each $card in $cards {
        [data-card="#{$card}"] {
          -ms-grid-column: 2 * ($i % 3) + 1;
          -ms-grid-row: 2 * floor($i / 3) + 1;
        }

        $i: $i + 1;
      }
    }
  }

  &.kpis .grid-container {
    -ms-grid-columns: 100%;
    -ms-grid-rows: auto 15px auto 15px auto;

    [data-card="most"] {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    [data-card="time"] {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    [data-card="count"] {
      -ms-grid-column: 1;
      -ms-grid-row: 5;
    }

    @media (min-width: $screen-md-min) {
      -ms-grid-columns: 30% 15px calc(70% - 15px);
      -ms-grid-rows: auto 15px auto;

      [data-card="most"] {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
      }

      [data-card="time"] {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      [data-card="count"] {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
    }
  }

  // Other good browsers
  @supports (display: grid) {
    .grid-container {
      display: grid;
      grid-gap: 15px;
    }

    &.analytics .grid-container {
      grid-template-columns: 100%;

      @media (min-width: $screen-md-min) {
        &.top-container {
          grid-template-columns: 30% calc(70% - 15px);
        }

        &.pies-container {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    &.kpis .grid-container {
      grid-template-columns: 100%;
      grid-template-areas: "most" "time" "count";

      @media (min-width: $screen-md-min) {
        grid-template-columns: 30% calc(70% - 15px);
        grid-template-areas: "most time" "most count";
      }

      [data-card="most"] {
        grid-area: most;
      }

      [data-card="time"] {
        grid-area: time;
      }

      [data-card="count"] {
        grid-area: count;
      }
    }
  }

  .path-container {
    display: flex;
    flex-wrap: wrap;

    span {
      padding: 2px 5px;
      background-color: $color-blue;
      color: $color-white;

      &.odd {
        background-color: $color-dark-grey;
      }
    }
  }
}

// Understand > Devices > Statistics
.understand.devices.statistics {
  h2 {
    display: flex;
    align-items: center;

    .fas {
      margin-left: 5px;
      font-size: 60%;
    }
  }

  .tiles-container {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    .tile {
      margin: 5px;
      width: 100%;
      min-height: auto;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    }

    .tile-image {
      margin-top: 25px;
    }
  }

  .last-users-container {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    .last-user {
      display: flex;
      margin: 5px;
      width: 100%;
      border-radius: 4px;
      background: $color-white;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    }

    .os-and-type {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      width: 35px;
      margin-right: 5px;
      border-radius: 4px 0 0 4px;
      background-color: $color-light-blue-alt;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-height: 50px;
        color: $color-white;
        text-align: center;
      }

      i + i {
        border-top: 1px solid $color-white;
      }

      .fas {
        font-size: 20px;
      }

      .fab {
        font-size: 30px;
      }
    }

    .device-data {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      padding: 5px 0;
      font-size: 13px;
    }

    time {
      color: $color-light-grey;
    }

    .device-uid a {
      cursor: pointer;
    }

    time,
    .device-name,
    .device-model,
    .device-uid {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .distribution-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -5px;

    .chart-container {
      margin: 5px;
      padding: 20px;
      width: 100%;
      background: $color-white;
      border-radius: 4px;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    }
  }

  .details-container {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    section {
      margin: 5px;
      width: 100%;
    }

    header {
      padding: 10px;
      background-color: #f3f3f3;
    }

    h3 {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .badge {
      margin-left: auto;
    }

    ul {
      margin: 0;
      padding: 0;
      max-height: 250px;
      background-color: $color-white;
      list-style: none;
      overflow-y: auto;
    }

    li {
      display: flex;
      align-items: center;
      padding: 10px;
      white-space: nowrap;
    }

    li + li {
      border-top: 1px solid #ccc;
    }

    .key {
      margin-right: 5px;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .value {
      margin-left: auto;
    }
  }

  @media (min-width: $screen-sm-min) {
    .tiles-container .tile {
      width: calc(50% - 10px);
    }

    .last-users-container .last-user {
      width: calc(50% - 10px);
    }

    .details-container section {
      width: calc(50% - 10px);
    }
  }

  @media (min-width: $screen-md-min) {
    .tiles-container .tile {
      width: calc((100% / 3) - 10px);
    }

    .last-users-container .last-user {
      width: calc((100% / 3) - 10px);
    }

    .distribution-container .chart-container {
      width: calc(50% - 10px);
    }

    .details-container section {
      width: calc((100% / 3) - 10px);
    }
  }

  @media (min-width: $screen-lg-min) {
    .tiles-container .tile {
      width: calc((100% / 6) - 10px);
    }

    .last-users-container .last-user {
      width: calc((100% / 6) - 10px);
    }

    .details-container section {
      width: calc(20% - 10px);
    }
  }
}

// Understand > Devices > Console
.understand.devices.console {
  .tabs-list {
    display: flex;
    flex-wrap: wrap;

    > li {
      float: none;
    }

    > li > button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    > li:not(:last-child) > button {
      margin-right: 2px;
    }
  }

  #filters-wrapper {
    background-color: $color-white;
    border: solid #dddddd;
    border-width: 0 1px;
  }

  .table-container {
    border: solid #dddddd;
    border-width: 0 1px 1px 1px;
  }

  .table-container .table {
    margin: 0;
  }

  .table-container tbody .device-uid {
    font-family: $font-console;
  }

  .first {
    cursor: pointer;
  }

  .even {
    background-color: #f9f9f9;
  }

  h3,
  .h3 {
    margin-top: 0;
    color: #3b87e9;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
