.administration {
  table.dataTable td,
  table.dataTable th {
    box-sizing: border-box;
  }

  .application-result {
    display: flex;
    align-items: center;

    img {
      max-height: 40px;
      width: auto;
      margin-right: 15px;
    }

    .environment {
      margin-left: 5px;
      color: $color-dark-grey;
    }
  }
}

//=== Administration > Continuous integration
.administration.continuous-integration.settings {
  .input-firebase-app-id {
    min-width: 250px;
  }

  .column-environment {
    min-width: 375px;
  }

  .column-environment ~ th {
    width: 50%;
  }

  .cell-label-production {
    width: 0;
  }
}

//=== Administration > Back office > Global users
.administration {
  #modal-add-access {
    tbody:not(:empty) + tfoot {
      display: none;
    }
  }

  #modal-permissions .table > tbody > tr > td {
    vertical-align: top;
  }

  &.global-users {
    .selection-applications .select2-selection__rendered,
    .dropdown-applications .select2-results__option {
      display: flex;
      align-items: center;
      line-height: 1;
    }

    .selection-applications .select2-selection__rendered {
      height: 100%;
    }

    .selection-applications img {
      max-width: 28px;
      max-height: 28px;
      margin-right: 5px;
    }

    .selection-applications small {
      margin-left: 5px;
    }

    .dropdown-applications img {
      max-width: 32px;
      max-height: 32px;
      margin-right: 5px;
    }

    .selection-groups .select2-selection__rendered {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
