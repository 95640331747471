.main-tabs {
  margin: -20px -20px 20px -20px;

  > ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    background-color: $color-darker-blue;
    list-style: none;
    overflow-x: auto;
  }

  > ul li.active a {
    border-bottom-color: $color-light-blue;
    color: #ffffff;
    font-weight: 700;
  }

  > ul a {
    display: inline-block;
    height: 52px;
    padding: 14px 30px;
    border-bottom: 6px solid $color-darker-blue;
    color: rgba(255, 255, 255, 0.65);
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover,
    &:focus {
      border-bottom-color: $color-dark-blue;
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
    }
  }

  > .tab-content >.tab-pane {
    padding: 20px;
  }
}

.tabs-container {
  .tab-pane {
    padding: 10px;
    background-color: $color-white;
    border: 1px solid #ddd;
    border-top: none;
  }

  .tab-pane h2:first-child {
    margin-top: 0;
  }
}