// == Interact > Polls
.interact.polls {
  [data-field="filter"] .input-list > .input-group {
    margin-bottom: 15px;
  }
}

// == Interact > Push > Forms
.interact.push.form {
  .form-and-preview-container {
    display: flex;
    min-height: calc(100vh - 180px);
  }

  // Form
  .form-container {
    flex: 1;
  }

  // Re-establish Bootstrap's style
  .form-container .radio ~ .radio {
    margin-top: -5px;
  }

  .form-container .radio + [data-related-to] {
    margin: 5px 0 10px 0;
    padding-left: 20px;
  }

  .form-container .input-group-flex .input-group + .input-group,
  .form-container .input-group-flex .input-group-flex + .input-group-flex {
    @media (max-width: $screen-xxs-max) {
      margin-top: 5px;
    }

    @media (min-width: $screen-xs-min) {
      margin-left: 5px;
    }
  }

  // Preview
  .preview-container {
    display: none;
    width: 350px;
    margin: -20px -20px -50px 20px;
    background-color: #eeeeee;
  }

  .preview-inner {
    position: sticky;
    top: 58px;
    padding: 15px 0;
  }

  .os-list {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 25px;

    a:hover {
      text-decoration: none;
    }

    a ~ a {
      margin-left: 25px;
    }
  }

  .carousel {
    height: 307px;
  }

  .carousel-inner > .item > img {
    margin: 0 auto;
  }

  .carousel-indicators {
    margin-bottom: 0;
    bottom: 1px;

    li {
      border-color: #333333;
    }

    .active {
      background-color: #333333;
    }
  }

  .carousel-control {
    background-image: none;
  }

  .notification-metadata {
    padding: 0 15px;
    text-align: center;

    .fa-spin {
      margin-right: 5px;
    }
  }

  @media (min-width: $screen-md-min) {
    .preview-container {
      display: block;
    }

    .main-footer {
      right: 365px;
    }
  }
}

// == Interact > Push > History
.interact.push.history {
  .form-header {
    margin-top: 0;
  }

  table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
  }

  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

// == Interact > Push > Settings
.interact.push.settings {
  .pre-scrollable {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .certificates-list + .help-block {
    padding: 10px;
    border-top: 1px solid #dddddd;
  }

  .certificate-group {
    margin: 0;
    padding: 10px 10px 0 10px;
    border-top: 1px solid #dddddd;
  }

  #ios-footer {
    border-top: 1px solid #dddddd;
  }
}
