// Distribute > YourAppStore
.distribute.your-app-store {
  #modal-qr-code {
    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 100%;
    }
  }
}
