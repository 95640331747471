.badge-primary {
  background-color: $color-primary;
}

.badge-secondary {
  background-color: $color-light-blue;
}

.badge-success {
  background-color: $color-success;
}

.badge-info {
  background-color: $color-info;
}

.badge-warning {
  background-color: $color-warning;
}

.badge-danger {
  background-color: $color-danger;
}