.switchable-holder {
  height: 14px;
  margin: 0;
  background-color: #e6e6e6;

  .switchable-switcher {
    position: relative;
    top: -4.5px;
    width: 21px;
    height: 21px;
  }

  &.switchable-checked {
    background-color: #295ea1;

    .switchable-switcher {
      background-color: #3b87e9;
      transform: translateX(15px);
    }
  }

  // Read only
  // Disabled
  &.switchable-readonly,
  &.switchable-disabled {
    .switchable-switcher {
      background-color: $color-light-grey;
      cursor: not-allowed;
    }

    &.switchable-checked {
      background-color: $color-light-grey;

      .switchable-switcher {
        background-color: $color-grey;
      }
    }
  }
}