// Redefine Bootstrap buttons
.btn-default,
.btn-primary,
.btn-secondary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  height: 37px;
  padding: 0 25px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
  border: none;
  border-radius: 3px;
  color: $color-white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-default {
  color: #333;
}

a.btn,
label.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

// Redefine button sizes
.btn-group-xs > .btn,
.btn-xs {
  height: 22px;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0 15px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 6px 16px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 1.3333333;
}

// Redefine Bootstrap buttons colors
.btn-primary {
  @include button-variant($color-blue);
}

.btn-secondary {
  @include button-variant($color-light-blue);

  &:hover,
  &:active, &.active,
  &:focus, &.focus {
    color: $color-white;
  }
}

.btn-success {
  @include button-variant($color-mint-green);
}

.btn-danger {
  @include button-variant(#eb1d1d);
}

.btn-warning {
  @include button-variant($color-yellow);
}

/* OpenID buttons */
$open-id: (
    google: #dd4b39,
    facebook: #3b5998,
    github: #333,
    gitlab: #fc6d26,
    bitbucket: #205081,
    linkedin: #0077b5,
    slack: #e9a820,
    microsoft: #7cbb00,
    heroku: #6762a6,
    azure-active-directory: #00a1f1,
    apple: #8e8e93,
    generic: #000000,
);

.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  row-gap: 5px;
}

.btn.btn-open-id {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding: 0 5px;
  color: $color-white;
  text-align: center;
  font-weight: bold;
  font-variant: small-caps;

  .fas,
  .fab {
    margin-right: 10px;
    font-size: 20px;
  }

  .fab {
    font-weight: normal;
  }

  &:hover, &:focus {
    color: $color-white;
  }

  @each $provider, $color in $open-id {
    &.theme-#{$provider} {
      @include button-variant($color);
    }
  }
}

/* Toggling buttons */
.btn-toggle {
  @include button-variant($color-darker-blue);
  display: inline-block;
  width: 23px;
  height: 23px;
  padding: 0;
  border: none;
  border-radius: 50%;
  color: $color-white;

  &::before {
    line-height: 23px;
    font-size: 18px;
    font-weight: bold;
  }

  &:hover, &:focus {
    color: $color-white;
  }
}

.btn-toggle.toggle-plus-minus {
  &::before {
    content: '+';
  }

  &.toggled::before {
    content: '-';
  }
}

// Buttons with icons
.btn i.fa-spinner {
  margin-right: 5px;
}

/* Floating buttons */
.btn-floating {
  position: fixed;
  z-index: 5;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;

  .fas,
  .far,
  .fab {
    font-size: 16px;
  }
}
