@charset "UTF-8";
body {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
  color: #666666;
  font-size: 24px;
}

h2, .h2 {
  color: #3b87e9;
  font-size: 20px;
  font-weight: 600;
}

h3, .h3 {
  font-size: 16px;
  font-weight: 600;
}

.page-header {
  margin-top: 0;
}
.page-header h1, .page-header .h1 {
  margin-top: 0;
  margin-bottom: 0;
}
.page-header p {
  margin-bottom: 0;
  color: #666666;
  font-size: 14px;
}
.page-header.with-addon {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.page-header.with-addon .title-container + * {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .page-header.with-addon {
    align-items: center;
    flex-direction: row;
  }
  .page-header.with-addon .title-container {
    margin-right: 15px;
  }
  .page-header.with-addon .title-container + * {
    margin-top: 0;
    margin-left: auto;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.break-words {
  word-break: break-all;
}

.white-space-nowrap {
  white-space: nowrap;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.font-console {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
  background-color: #f8f8f8;
}

pre.pre-invisible {
  padding: 0;
  border: none;
  background: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre.pre-break-lines {
  white-space: pre-wrap;
  word-wrap: break-word;
}

code .tags {
  color: #ff0000;
}
code .keyword {
  color: #0000ff;
  font-weight: bold;
}
code .comment {
  color: #008000;
}
code .var {
  color: #000080;
}

/**
 * Bootstrap 3
 */
.help-block {
  margin-top: 0;
  margin-bottom: 0;
}

.control-label {
  font-weight: 700;
}
.control-label.required::after {
  content: " *";
  font-size: 13px;
}

div.form-control {
  min-height: 34px;
  height: auto;
}

input[type=date].form-control, input[type=time].form-control,
input[type=datetime-local].form-control, input[type=month].form-control {
  line-height: initial;
}

textarea {
  min-height: 34px;
  resize: vertical;
}

/**
 * Transparent forms
 *
 * @see http://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete/32505530#answer-37432260
 */
@keyframes autofill-icon-email {
  to {
    background-repeat: no-repeat;
    background-position: left 15px center;
    background-color: rgba(255, 255, 255, 0.08);
    background-image: url("/assets/images/ui/icon-email.png");
    background-image: image-set(url("/assets/images/ui/icon-email.png") 1x, url("/assets/images/ui/icon-email@2x.png") 2x, url("/assets/images/ui/icon-email@3x.png") 3x);
    color: #ffffff;
  }
}
@keyframes autofill-icon-lock {
  to {
    background-repeat: no-repeat;
    background-position: left 15px center;
    background-color: rgba(255, 255, 255, 0.08);
    background-image: url("/assets/images/ui/icon-lock.png");
    background-image: image-set(url("/assets/images/ui/icon-lock.png") 1x, url("/assets/images/ui/icon-lock@2x.png") 2x, url("/assets/images/ui/icon-lock@3x.png") 3x);
    color: #ffffff;
  }
}
.form-transparent input[type=text],
.form-transparent input[type=password],
.form-transparent input[type=email] {
  width: 100%;
  height: 43px;
  padding-left: 58px;
  padding-right: 23px;
  border: none;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-color: rgba(255, 255, 255, 0.08);
  color: #ffffff;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 14px;
}
.form-transparent input[type=text]:focus,
.form-transparent input[type=password]:focus,
.form-transparent input[type=email]:focus {
  outline: none;
}
.form-transparent input[type=text]::placeholder,
.form-transparent input[type=password]::placeholder,
.form-transparent input[type=email]::placeholder {
  color: rgba(255, 255, 255, 0.54);
  font-style: italic;
  opacity: 0.54;
}
.form-transparent input[type=text].icon-user,
.form-transparent input[type=password].icon-user,
.form-transparent input[type=email].icon-user {
  background-image: url("/assets/images/ui/icon-user.png");
  background-image: image-set(url("/assets/images/ui/icon-user.png") 1x, url("/assets/images/ui/icon-user@2x.png") 2x, url("/assets/images/ui/icon-user@3x.png") 3x);
}
.form-transparent input[type=text].icon-company,
.form-transparent input[type=password].icon-company,
.form-transparent input[type=email].icon-company {
  background-image: url("/assets/images/ui/icon-company.png");
  background-image: image-set(url("/assets/images/ui/icon-company.png") 1x, url("/assets/images/ui/icon-company@2x.png") 2x, url("/assets/images/ui/icon-company@3x.png") 3x);
}
.form-transparent input[type=text].icon-phone,
.form-transparent input[type=password].icon-phone,
.form-transparent input[type=email].icon-phone {
  background-image: url("/assets/images/ui/icon-phone.png");
  background-image: image-set(url("/assets/images/ui/icon-phone.png") 1x, url("/assets/images/ui/icon-phone@2x.png") 2x, url("/assets/images/ui/icon-phone@3x.png") 3x);
}
.form-transparent input[type=text].icon-lock,
.form-transparent input[type=password].icon-lock,
.form-transparent input[type=email].icon-lock {
  background-image: url("/assets/images/ui/icon-lock.png");
  background-image: image-set(url("/assets/images/ui/icon-lock.png") 1x, url("/assets/images/ui/icon-lock@2x.png") 2x, url("/assets/images/ui/icon-lock@3x.png") 3x);
}
.form-transparent input[type=text].icon-email,
.form-transparent input[type=password].icon-email,
.form-transparent input[type=email].icon-email {
  background-image: url("/assets/images/ui/icon-email.png");
  background-image: image-set(url("/assets/images/ui/icon-email.png") 1x, url("/assets/images/ui/icon-email@2x.png") 2x, url("/assets/images/ui/icon-email@3x.png") 3x);
}
.form-transparent .icon-email:-webkit-autofill {
  -webkit-animation-name: autofill-icon-email;
  -webkit-animation-fill-mode: both;
}
.form-transparent .icon-lock:-webkit-autofill {
  -webkit-animation-name: autofill-icon-lock;
  -webkit-animation-fill-mode: both;
}
.form-transparent .row + .row {
  margin-top: 10px;
}

/**
 * Custom <select>
 */
@supports (appareance: none) or (-moz-appearance: none) or (-webkit-appearance: none) {
  select.select {
    display: inline-block;
    height: 37px;
    padding: 0 20px 0 6px;
    background-image: url("/assets/images/ui/caret-grey.png");
    background-image: image-set(url("/assets/images/ui/caret-grey.png") 1x, url("/assets/images/ui/caret-grey@2x.png") 2x, url("/assets/images/ui/caret-grey@3x.png") 3x);
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ccc;
    appearance: none;
    color: rgba(102, 102, 102, 0.6);
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: normal;
  }
  select.select:hover, select.select:focus {
    background-image: url("/assets/images/ui/caret-grey-hover.png");
    background-image: image-set(url("/assets/images/ui/caret-grey-hover.png") 1x, url("/assets/images/ui/caret-grey-hover@2x.png") 2x, url("/assets/images/ui/caret-grey-hover@3x.png") 3x);
    border-color: #66afe9;
  }
  select.select:focus {
    outline: none;
  }
  select.select.not-empty {
    color: #666666;
  }
  select.select option {
    color: #666666;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 14px;
  }
}
/**
 * Lists
 */
.form-container .form-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px -5px 10px -5px;
}
.form-container .form-header > * {
  margin: 5px;
}
.form-container .form-header .switchable-holder {
  margin: 3px 5px 0 0;
}
.form-container .form-header .spacer {
  flex: 1;
}
.form-container .table-responsive,
.form-container table {
  margin-bottom: 0;
}
.form-container table thead th,
.form-container table thead td {
  vertical-align: middle;
}
.form-container table tbody tr.is-checked th,
.form-container table tbody tr.is-checked td {
  background-color: #3b87e9;
  color: #ffffff;
}
.form-container table tbody tr.is-checked th a,
.form-container table tbody tr.is-checked td a {
  color: #ffffff;
}
.form-container .table-actions .btn {
  background-color: #3b87e9;
  padding: 0 15px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}
.form-container .table-actions .btn:focus, .form-container .table-actions .btn.focus {
  background-color: #186cd9;
}
.form-container .table-actions .btn:hover {
  background-color: #186cd9;
}
.form-container .table-actions .btn:active, .form-container .table-actions .btn.active {
  background-color: #186cd9;
}
.form-container .table-actions .btn:active:hover, .form-container .table-actions .btn:active:focus, .form-container .table-actions .btn:active.focus, .form-container .table-actions .btn.active:hover, .form-container .table-actions .btn.active:focus, .form-container .table-actions .btn.active.focus {
  background-color: #155cb9;
}
.form-container .table-actions .btn:active, .form-container .table-actions .btn.active {
  background-image: none;
}
.form-container .table-actions .btn.disabled:hover, .form-container .table-actions .btn.disabled:focus, .form-container .table-actions .btn.disabled.focus, .form-container .table-actions .btn[disabled]:hover, .form-container .table-actions .btn[disabled]:focus, .form-container .table-actions .btn[disabled].focus, fieldset[disabled] .form-container .table-actions .btn:hover, fieldset[disabled] .form-container .table-actions .btn:focus, fieldset[disabled] .form-container .table-actions .btn.focus {
  background-color: #3b87e9;
}
.form-container .table-actions .btn:hover, .form-container .table-actions .btn:focus {
  color: #ffffff;
}
.form-container .table-actions .btn + .btn {
  border-left: 1px solid #ffffff;
}
@media (min-width: 768px) {
  .form-container .table-actions {
    position: absolute;
    left: 0;
    bottom: 15px;
    z-index: 10;
  }
}
.form-container .column-checkbox {
  width: 30px;
  text-align: center;
}
.form-container .column-checkbox .checkbox-container {
  margin-bottom: 0;
  line-height: 1;
}
.form-container .column-checkbox .checkbox-container input[type=checkbox] {
  display: none;
}
.form-container .column-checkbox .checkbox-container .checkbox-widget {
  display: inline-block;
  width: 15px;
  height: 14px;
  background-image: url("/assets/images/ui/forms/checkbox-off.png");
  background-image: image-set(url("/assets/images/ui/forms/checkbox-off.png") 1x, url("/assets/images/ui/forms/checkbox-off@2x.png") 2x, url("/assets/images/ui/forms/checkbox-off@3x.png") 3x);
  background-repeat: no-repeat;
  background-position: center center;
}
.form-container .column-checkbox .checkbox-container input[type=checkbox]:checked + .checkbox-widget {
  background-image: url("/assets/images/ui/forms/checkbox-on.png");
  background-image: image-set(url("/assets/images/ui/forms/checkbox-on.png") 1x, url("/assets/images/ui/forms/checkbox-on@2x.png") 2x, url("/assets/images/ui/forms/checkbox-on@3x.png") 3x);
}

/**
 * Modal forms
 */
.form-modal .form-control-static {
  min-height: auto;
  padding-bottom: 0;
}

/**
 * Select 2
 */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  min-height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #555;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 4px;
}
.select2-container--default.select2-container--open .select2-selection--single, .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #ccc;
}

.select2-dropdown {
  border-color: #ccc;
}

.input-group .form-control:first-child + .select2-container .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group .form-control:first-child + .select2-container + .input-group-btn > .btn {
  margin-left: 0;
}
.input-group .input-group-btn + .form-control + .select2-container .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .input-group-btn button {
  height: 34px;
  box-shadow: none;
}

.select2-search-result {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.select2-search-result .image-container {
  width: 60px;
}
.select2-search-result .image-container img {
  width: auto;
  max-width: 60px;
  height: auto;
  max-height: 60px;
}
.select2-search-result .text-container {
  flex: 1;
  max-height: 100px;
  padding-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Lists */
.input-list:not(:empty) {
  margin-bottom: 15px;
}

/* Custom inputs */
.input-group-flex .input-group-addon {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: auto;
}
.input-group-flex .input-group-btn {
  flex: 0 0 auto;
  width: auto;
}
.input-group-flex .btn {
  min-height: 34px;
  height: 100%;
}
.input-group-flex label.input-group-addon {
  margin-bottom: 0;
}
@media (max-width: 479px) {
  .input-group-flex .form-control + .form-control {
    margin-top: 5px;
  }
}
@media (min-width: 480px) {
  .input-group-flex {
    display: flex;
  }
  .input-group-flex > :first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-flex > :not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-flex > :last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-flex .select2-hidden-accessible:first-child + .select2-container:not(:last-child) .select2-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-flex .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) .select2-selection {
    border-radius: 0;
  }
  .input-group-flex .select2-hidden-accessible:not(:first-child) + .select2-container:last-child .select2-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/* Errors */
.form-errors {
  margin: 0;
  color: #a94442;
}

.table {
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 3px;
}
.table > thead > tr > th {
  background-color: #f3f3f3;
  border-bottom: none;
  color: #666666;
  font-size: 14px;
  font-weight: bold;
}
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  color: #666666;
  vertical-align: middle;
}
.table.table-striped-2-by-2 tbody tr:nth-of-type(4n+1),
.table.table-striped-2-by-2 tbody tr:nth-of-type(4n+2) {
  background-color: #f9f9f9;
}
.table.align-top > tbody > tr > th,
.table.align-top > tbody > tr > td {
  vertical-align: top;
}
.table .row-hint th,
.table .row-hint td {
  text-align: center;
}
.table .column-thumbnail {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .column-thumbnail .img-thumbnail {
  max-width: 150px;
  max-height: 150px;
}
.table .column-icon {
  width: 25px;
  max-width: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table td.column-icon {
  text-align: center;
  font-size: 20px;
}
.table .column-position {
  width: 30px;
  text-align: center;
}
.table .column-actions {
  text-align: center;
  white-space: nowrap;
}
.table .column-actions .fa,
.table .column-actions .fas,
.table .column-actions .far,
.table .column-actions .fab {
  font-size: 20px;
}
.table .column-actions > * ~ :not(.tooltip) {
  margin-left: 10px;
}
.table .column-actions a:hover,
.table .column-actions a:focus {
  text-decoration: none;
}

.badge-primary {
  background-color: #337ab7;
}

.badge-secondary {
  background-color: #3b87e9;
}

.badge-success {
  background-color: #5cb85c;
}

.badge-info {
  background-color: #5bc0de;
}

.badge-warning {
  background-color: #f0ad4e;
}

.badge-danger {
  background-color: #d9534f;
}

.btn-default,
.btn-primary,
.btn-secondary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  height: 37px;
  padding: 0 25px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
  border: none;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-default {
  color: #333;
}

a.btn,
label.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-group-xs > .btn,
.btn-xs {
  height: 22px;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0 15px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 6px 16px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 1.3333333;
}

.btn-primary {
  background-color: #2c65ad;
}
.btn-primary:focus, .btn-primary.focus {
  background-color: #224d84;
}
.btn-primary:hover {
  background-color: #224d84;
}
.btn-primary:active, .btn-primary.active {
  background-color: #224d84;
}
.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus {
  background-color: #1a3d68;
}
.btn-primary:active, .btn-primary.active {
  background-image: none;
}
.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
  background-color: #2c65ad;
}

.btn-secondary {
  background-color: #3b87e9;
}
.btn-secondary:focus, .btn-secondary.focus {
  background-color: #186cd9;
}
.btn-secondary:hover {
  background-color: #186cd9;
}
.btn-secondary:active, .btn-secondary.active {
  background-color: #186cd9;
}
.btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus {
  background-color: #155cb9;
}
.btn-secondary:active, .btn-secondary.active {
  background-image: none;
}
.btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus, fieldset[disabled] .btn-secondary:hover, fieldset[disabled] .btn-secondary:focus, fieldset[disabled] .btn-secondary.focus {
  background-color: #3b87e9;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary.active, .btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
}

.btn-success {
  background-color: #4bd16d;
}
.btn-success:focus, .btn-success.focus {
  background-color: #2fba52;
}
.btn-success:hover {
  background-color: #2fba52;
}
.btn-success:active, .btn-success.active {
  background-color: #2fba52;
}
.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus {
  background-color: #289d46;
}
.btn-success:active, .btn-success.active {
  background-image: none;
}
.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
  background-color: #4bd16d;
}

.btn-danger {
  background-color: #eb1d1d;
}
.btn-danger:focus, .btn-danger.focus {
  background-color: #c41111;
}
.btn-danger:hover {
  background-color: #c41111;
}
.btn-danger:active, .btn-danger.active {
  background-color: #c41111;
}
.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus {
  background-color: #a30e0e;
}
.btn-danger:active, .btn-danger.active {
  background-image: none;
}
.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
  background-color: #eb1d1d;
}

.btn-warning {
  background-color: #f0ad4e;
}
.btn-warning:focus, .btn-warning.focus {
  background-color: #ec971f;
}
.btn-warning:hover {
  background-color: #ec971f;
}
.btn-warning:active, .btn-warning.active {
  background-color: #ec971f;
}
.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus {
  background-color: #d58512;
}
.btn-warning:active, .btn-warning.active {
  background-image: none;
}
.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
}

/* OpenID buttons */
.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  row-gap: 5px;
}

.btn.btn-open-id {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding: 0 5px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-variant: small-caps;
}
.btn.btn-open-id .fas,
.btn.btn-open-id .fab {
  margin-right: 10px;
  font-size: 20px;
}
.btn.btn-open-id .fab {
  font-weight: normal;
}
.btn.btn-open-id:hover, .btn.btn-open-id:focus {
  color: #ffffff;
}
.btn.btn-open-id.theme-google {
  background-color: #dd4b39;
}
.btn.btn-open-id.theme-google:focus, .btn.btn-open-id.theme-google.focus {
  background-color: #c23321;
}
.btn.btn-open-id.theme-google:hover {
  background-color: #c23321;
}
.btn.btn-open-id.theme-google:active, .btn.btn-open-id.theme-google.active {
  background-color: #c23321;
}
.btn.btn-open-id.theme-google:active:hover, .btn.btn-open-id.theme-google:active:focus, .btn.btn-open-id.theme-google:active.focus, .btn.btn-open-id.theme-google.active:hover, .btn.btn-open-id.theme-google.active:focus, .btn.btn-open-id.theme-google.active.focus {
  background-color: #a32b1c;
}
.btn.btn-open-id.theme-google:active, .btn.btn-open-id.theme-google.active {
  background-image: none;
}
.btn.btn-open-id.theme-google.disabled:hover, .btn.btn-open-id.theme-google.disabled:focus, .btn.btn-open-id.theme-google.disabled.focus, .btn.btn-open-id.theme-google[disabled]:hover, .btn.btn-open-id.theme-google[disabled]:focus, .btn.btn-open-id.theme-google[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-google:hover, fieldset[disabled] .btn.btn-open-id.theme-google:focus, fieldset[disabled] .btn.btn-open-id.theme-google.focus {
  background-color: #dd4b39;
}
.btn.btn-open-id.theme-facebook {
  background-color: #3b5998;
}
.btn.btn-open-id.theme-facebook:focus, .btn.btn-open-id.theme-facebook.focus {
  background-color: #2d4373;
}
.btn.btn-open-id.theme-facebook:hover {
  background-color: #2d4373;
}
.btn.btn-open-id.theme-facebook:active, .btn.btn-open-id.theme-facebook.active {
  background-color: #2d4373;
}
.btn.btn-open-id.theme-facebook:active:hover, .btn.btn-open-id.theme-facebook:active:focus, .btn.btn-open-id.theme-facebook:active.focus, .btn.btn-open-id.theme-facebook.active:hover, .btn.btn-open-id.theme-facebook.active:focus, .btn.btn-open-id.theme-facebook.active.focus {
  background-color: #23345a;
}
.btn.btn-open-id.theme-facebook:active, .btn.btn-open-id.theme-facebook.active {
  background-image: none;
}
.btn.btn-open-id.theme-facebook.disabled:hover, .btn.btn-open-id.theme-facebook.disabled:focus, .btn.btn-open-id.theme-facebook.disabled.focus, .btn.btn-open-id.theme-facebook[disabled]:hover, .btn.btn-open-id.theme-facebook[disabled]:focus, .btn.btn-open-id.theme-facebook[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-facebook:hover, fieldset[disabled] .btn.btn-open-id.theme-facebook:focus, fieldset[disabled] .btn.btn-open-id.theme-facebook.focus {
  background-color: #3b5998;
}
.btn.btn-open-id.theme-github {
  background-color: #333;
}
.btn.btn-open-id.theme-github:focus, .btn.btn-open-id.theme-github.focus {
  background-color: #1a1a1a;
}
.btn.btn-open-id.theme-github:hover {
  background-color: #1a1a1a;
}
.btn.btn-open-id.theme-github:active, .btn.btn-open-id.theme-github.active {
  background-color: #1a1a1a;
}
.btn.btn-open-id.theme-github:active:hover, .btn.btn-open-id.theme-github:active:focus, .btn.btn-open-id.theme-github:active.focus, .btn.btn-open-id.theme-github.active:hover, .btn.btn-open-id.theme-github.active:focus, .btn.btn-open-id.theme-github.active.focus {
  background-color: #080808;
}
.btn.btn-open-id.theme-github:active, .btn.btn-open-id.theme-github.active {
  background-image: none;
}
.btn.btn-open-id.theme-github.disabled:hover, .btn.btn-open-id.theme-github.disabled:focus, .btn.btn-open-id.theme-github.disabled.focus, .btn.btn-open-id.theme-github[disabled]:hover, .btn.btn-open-id.theme-github[disabled]:focus, .btn.btn-open-id.theme-github[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-github:hover, fieldset[disabled] .btn.btn-open-id.theme-github:focus, fieldset[disabled] .btn.btn-open-id.theme-github.focus {
  background-color: #333;
}
.btn.btn-open-id.theme-gitlab {
  background-color: #fc6d26;
}
.btn.btn-open-id.theme-gitlab:focus, .btn.btn-open-id.theme-gitlab.focus {
  background-color: #ec5003;
}
.btn.btn-open-id.theme-gitlab:hover {
  background-color: #ec5003;
}
.btn.btn-open-id.theme-gitlab:active, .btn.btn-open-id.theme-gitlab.active {
  background-color: #ec5003;
}
.btn.btn-open-id.theme-gitlab:active:hover, .btn.btn-open-id.theme-gitlab:active:focus, .btn.btn-open-id.theme-gitlab:active.focus, .btn.btn-open-id.theme-gitlab.active:hover, .btn.btn-open-id.theme-gitlab.active:focus, .btn.btn-open-id.theme-gitlab.active.focus {
  background-color: #c94403;
}
.btn.btn-open-id.theme-gitlab:active, .btn.btn-open-id.theme-gitlab.active {
  background-image: none;
}
.btn.btn-open-id.theme-gitlab.disabled:hover, .btn.btn-open-id.theme-gitlab.disabled:focus, .btn.btn-open-id.theme-gitlab.disabled.focus, .btn.btn-open-id.theme-gitlab[disabled]:hover, .btn.btn-open-id.theme-gitlab[disabled]:focus, .btn.btn-open-id.theme-gitlab[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-gitlab:hover, fieldset[disabled] .btn.btn-open-id.theme-gitlab:focus, fieldset[disabled] .btn.btn-open-id.theme-gitlab.focus {
  background-color: #fc6d26;
}
.btn.btn-open-id.theme-bitbucket {
  background-color: #205081;
}
.btn.btn-open-id.theme-bitbucket:focus, .btn.btn-open-id.theme-bitbucket.focus {
  background-color: #163758;
}
.btn.btn-open-id.theme-bitbucket:hover {
  background-color: #163758;
}
.btn.btn-open-id.theme-bitbucket:active, .btn.btn-open-id.theme-bitbucket.active {
  background-color: #163758;
}
.btn.btn-open-id.theme-bitbucket:active:hover, .btn.btn-open-id.theme-bitbucket:active:focus, .btn.btn-open-id.theme-bitbucket:active.focus, .btn.btn-open-id.theme-bitbucket.active:hover, .btn.btn-open-id.theme-bitbucket.active:focus, .btn.btn-open-id.theme-bitbucket.active.focus {
  background-color: #0f253c;
}
.btn.btn-open-id.theme-bitbucket:active, .btn.btn-open-id.theme-bitbucket.active {
  background-image: none;
}
.btn.btn-open-id.theme-bitbucket.disabled:hover, .btn.btn-open-id.theme-bitbucket.disabled:focus, .btn.btn-open-id.theme-bitbucket.disabled.focus, .btn.btn-open-id.theme-bitbucket[disabled]:hover, .btn.btn-open-id.theme-bitbucket[disabled]:focus, .btn.btn-open-id.theme-bitbucket[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-bitbucket:hover, fieldset[disabled] .btn.btn-open-id.theme-bitbucket:focus, fieldset[disabled] .btn.btn-open-id.theme-bitbucket.focus {
  background-color: #205081;
}
.btn.btn-open-id.theme-linkedin {
  background-color: #0077b5;
}
.btn.btn-open-id.theme-linkedin:focus, .btn.btn-open-id.theme-linkedin.focus {
  background-color: #005582;
}
.btn.btn-open-id.theme-linkedin:hover {
  background-color: #005582;
}
.btn.btn-open-id.theme-linkedin:active, .btn.btn-open-id.theme-linkedin.active {
  background-color: #005582;
}
.btn.btn-open-id.theme-linkedin:active:hover, .btn.btn-open-id.theme-linkedin:active:focus, .btn.btn-open-id.theme-linkedin:active.focus, .btn.btn-open-id.theme-linkedin.active:hover, .btn.btn-open-id.theme-linkedin.active:focus, .btn.btn-open-id.theme-linkedin.active.focus {
  background-color: #003e5e;
}
.btn.btn-open-id.theme-linkedin:active, .btn.btn-open-id.theme-linkedin.active {
  background-image: none;
}
.btn.btn-open-id.theme-linkedin.disabled:hover, .btn.btn-open-id.theme-linkedin.disabled:focus, .btn.btn-open-id.theme-linkedin.disabled.focus, .btn.btn-open-id.theme-linkedin[disabled]:hover, .btn.btn-open-id.theme-linkedin[disabled]:focus, .btn.btn-open-id.theme-linkedin[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-linkedin:hover, fieldset[disabled] .btn.btn-open-id.theme-linkedin:focus, fieldset[disabled] .btn.btn-open-id.theme-linkedin.focus {
  background-color: #0077b5;
}
.btn.btn-open-id.theme-slack {
  background-color: #e9a820;
}
.btn.btn-open-id.theme-slack:focus, .btn.btn-open-id.theme-slack.focus {
  background-color: #c38a13;
}
.btn.btn-open-id.theme-slack:hover {
  background-color: #c38a13;
}
.btn.btn-open-id.theme-slack:active, .btn.btn-open-id.theme-slack.active {
  background-color: #c38a13;
}
.btn.btn-open-id.theme-slack:active:hover, .btn.btn-open-id.theme-slack:active:focus, .btn.btn-open-id.theme-slack:active.focus, .btn.btn-open-id.theme-slack.active:hover, .btn.btn-open-id.theme-slack.active:focus, .btn.btn-open-id.theme-slack.active.focus {
  background-color: #a27310;
}
.btn.btn-open-id.theme-slack:active, .btn.btn-open-id.theme-slack.active {
  background-image: none;
}
.btn.btn-open-id.theme-slack.disabled:hover, .btn.btn-open-id.theme-slack.disabled:focus, .btn.btn-open-id.theme-slack.disabled.focus, .btn.btn-open-id.theme-slack[disabled]:hover, .btn.btn-open-id.theme-slack[disabled]:focus, .btn.btn-open-id.theme-slack[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-slack:hover, fieldset[disabled] .btn.btn-open-id.theme-slack:focus, fieldset[disabled] .btn.btn-open-id.theme-slack.focus {
  background-color: #e9a820;
}
.btn.btn-open-id.theme-microsoft {
  background-color: #7cbb00;
}
.btn.btn-open-id.theme-microsoft:focus, .btn.btn-open-id.theme-microsoft.focus {
  background-color: #5a8800;
}
.btn.btn-open-id.theme-microsoft:hover {
  background-color: #5a8800;
}
.btn.btn-open-id.theme-microsoft:active, .btn.btn-open-id.theme-microsoft.active {
  background-color: #5a8800;
}
.btn.btn-open-id.theme-microsoft:active:hover, .btn.btn-open-id.theme-microsoft:active:focus, .btn.btn-open-id.theme-microsoft:active.focus, .btn.btn-open-id.theme-microsoft.active:hover, .btn.btn-open-id.theme-microsoft.active:focus, .btn.btn-open-id.theme-microsoft.active.focus {
  background-color: #436400;
}
.btn.btn-open-id.theme-microsoft:active, .btn.btn-open-id.theme-microsoft.active {
  background-image: none;
}
.btn.btn-open-id.theme-microsoft.disabled:hover, .btn.btn-open-id.theme-microsoft.disabled:focus, .btn.btn-open-id.theme-microsoft.disabled.focus, .btn.btn-open-id.theme-microsoft[disabled]:hover, .btn.btn-open-id.theme-microsoft[disabled]:focus, .btn.btn-open-id.theme-microsoft[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-microsoft:hover, fieldset[disabled] .btn.btn-open-id.theme-microsoft:focus, fieldset[disabled] .btn.btn-open-id.theme-microsoft.focus {
  background-color: #7cbb00;
}
.btn.btn-open-id.theme-heroku {
  background-color: #6762a6;
}
.btn.btn-open-id.theme-heroku:focus, .btn.btn-open-id.theme-heroku.focus {
  background-color: #514d88;
}
.btn.btn-open-id.theme-heroku:hover {
  background-color: #514d88;
}
.btn.btn-open-id.theme-heroku:active, .btn.btn-open-id.theme-heroku.active {
  background-color: #514d88;
}
.btn.btn-open-id.theme-heroku:active:hover, .btn.btn-open-id.theme-heroku:active:focus, .btn.btn-open-id.theme-heroku:active.focus, .btn.btn-open-id.theme-heroku.active:hover, .btn.btn-open-id.theme-heroku.active:focus, .btn.btn-open-id.theme-heroku.active.focus {
  background-color: #444071;
}
.btn.btn-open-id.theme-heroku:active, .btn.btn-open-id.theme-heroku.active {
  background-image: none;
}
.btn.btn-open-id.theme-heroku.disabled:hover, .btn.btn-open-id.theme-heroku.disabled:focus, .btn.btn-open-id.theme-heroku.disabled.focus, .btn.btn-open-id.theme-heroku[disabled]:hover, .btn.btn-open-id.theme-heroku[disabled]:focus, .btn.btn-open-id.theme-heroku[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-heroku:hover, fieldset[disabled] .btn.btn-open-id.theme-heroku:focus, fieldset[disabled] .btn.btn-open-id.theme-heroku.focus {
  background-color: #6762a6;
}
.btn.btn-open-id.theme-azure-active-directory {
  background-color: #00a1f1;
}
.btn.btn-open-id.theme-azure-active-directory:focus, .btn.btn-open-id.theme-azure-active-directory.focus {
  background-color: #007fbe;
}
.btn.btn-open-id.theme-azure-active-directory:hover {
  background-color: #007fbe;
}
.btn.btn-open-id.theme-azure-active-directory:active, .btn.btn-open-id.theme-azure-active-directory.active {
  background-color: #007fbe;
}
.btn.btn-open-id.theme-azure-active-directory:active:hover, .btn.btn-open-id.theme-azure-active-directory:active:focus, .btn.btn-open-id.theme-azure-active-directory:active.focus, .btn.btn-open-id.theme-azure-active-directory.active:hover, .btn.btn-open-id.theme-azure-active-directory.active:focus, .btn.btn-open-id.theme-azure-active-directory.active.focus {
  background-color: #00679a;
}
.btn.btn-open-id.theme-azure-active-directory:active, .btn.btn-open-id.theme-azure-active-directory.active {
  background-image: none;
}
.btn.btn-open-id.theme-azure-active-directory.disabled:hover, .btn.btn-open-id.theme-azure-active-directory.disabled:focus, .btn.btn-open-id.theme-azure-active-directory.disabled.focus, .btn.btn-open-id.theme-azure-active-directory[disabled]:hover, .btn.btn-open-id.theme-azure-active-directory[disabled]:focus, .btn.btn-open-id.theme-azure-active-directory[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-azure-active-directory:hover, fieldset[disabled] .btn.btn-open-id.theme-azure-active-directory:focus, fieldset[disabled] .btn.btn-open-id.theme-azure-active-directory.focus {
  background-color: #00a1f1;
}
.btn.btn-open-id.theme-apple {
  background-color: #8e8e93;
}
.btn.btn-open-id.theme-apple:focus, .btn.btn-open-id.theme-apple.focus {
  background-color: #74747a;
}
.btn.btn-open-id.theme-apple:hover {
  background-color: #74747a;
}
.btn.btn-open-id.theme-apple:active, .btn.btn-open-id.theme-apple.active {
  background-color: #74747a;
}
.btn.btn-open-id.theme-apple:active:hover, .btn.btn-open-id.theme-apple:active:focus, .btn.btn-open-id.theme-apple:active.focus, .btn.btn-open-id.theme-apple.active:hover, .btn.btn-open-id.theme-apple.active:focus, .btn.btn-open-id.theme-apple.active.focus {
  background-color: #636367;
}
.btn.btn-open-id.theme-apple:active, .btn.btn-open-id.theme-apple.active {
  background-image: none;
}
.btn.btn-open-id.theme-apple.disabled:hover, .btn.btn-open-id.theme-apple.disabled:focus, .btn.btn-open-id.theme-apple.disabled.focus, .btn.btn-open-id.theme-apple[disabled]:hover, .btn.btn-open-id.theme-apple[disabled]:focus, .btn.btn-open-id.theme-apple[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-apple:hover, fieldset[disabled] .btn.btn-open-id.theme-apple:focus, fieldset[disabled] .btn.btn-open-id.theme-apple.focus {
  background-color: #8e8e93;
}
.btn.btn-open-id.theme-generic {
  background-color: #000000;
}
.btn.btn-open-id.theme-generic:focus, .btn.btn-open-id.theme-generic.focus {
  background-color: black;
}
.btn.btn-open-id.theme-generic:hover {
  background-color: black;
}
.btn.btn-open-id.theme-generic:active, .btn.btn-open-id.theme-generic.active {
  background-color: black;
}
.btn.btn-open-id.theme-generic:active:hover, .btn.btn-open-id.theme-generic:active:focus, .btn.btn-open-id.theme-generic:active.focus, .btn.btn-open-id.theme-generic.active:hover, .btn.btn-open-id.theme-generic.active:focus, .btn.btn-open-id.theme-generic.active.focus {
  background-color: black;
}
.btn.btn-open-id.theme-generic:active, .btn.btn-open-id.theme-generic.active {
  background-image: none;
}
.btn.btn-open-id.theme-generic.disabled:hover, .btn.btn-open-id.theme-generic.disabled:focus, .btn.btn-open-id.theme-generic.disabled.focus, .btn.btn-open-id.theme-generic[disabled]:hover, .btn.btn-open-id.theme-generic[disabled]:focus, .btn.btn-open-id.theme-generic[disabled].focus, fieldset[disabled] .btn.btn-open-id.theme-generic:hover, fieldset[disabled] .btn.btn-open-id.theme-generic:focus, fieldset[disabled] .btn.btn-open-id.theme-generic.focus {
  background-color: #000000;
}

/* Toggling buttons */
.btn-toggle {
  background-color: #0f243d;
  display: inline-block;
  width: 23px;
  height: 23px;
  padding: 0;
  border: none;
  border-radius: 50%;
  color: #ffffff;
}
.btn-toggle:focus, .btn-toggle.focus {
  background-color: #050c14;
}
.btn-toggle:hover {
  background-color: #050c14;
}
.btn-toggle:active, .btn-toggle.active {
  background-color: #050c14;
}
.btn-toggle:active:hover, .btn-toggle:active:focus, .btn-toggle:active.focus, .btn-toggle.active:hover, .btn-toggle.active:focus, .btn-toggle.active.focus {
  background-color: black;
}
.btn-toggle:active, .btn-toggle.active {
  background-image: none;
}
.btn-toggle.disabled:hover, .btn-toggle.disabled:focus, .btn-toggle.disabled.focus, .btn-toggle[disabled]:hover, .btn-toggle[disabled]:focus, .btn-toggle[disabled].focus, fieldset[disabled] .btn-toggle:hover, fieldset[disabled] .btn-toggle:focus, fieldset[disabled] .btn-toggle.focus {
  background-color: #0f243d;
}
.btn-toggle::before {
  line-height: 23px;
  font-size: 18px;
  font-weight: bold;
}
.btn-toggle:hover, .btn-toggle:focus {
  color: #ffffff;
}

.btn-toggle.toggle-plus-minus::before {
  content: "+";
}
.btn-toggle.toggle-plus-minus.toggled::before {
  content: "-";
}

.btn i.fa-spinner {
  margin-right: 5px;
}

/* Floating buttons */
.btn-floating {
  position: fixed;
  z-index: 5;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
}
.btn-floating .fas,
.btn-floating .far,
.btn-floating .fab {
  font-size: 16px;
}

@keyframes card-loading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.card {
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.card.loading::before, .card.loading::after {
  position: absolute;
}
.card.loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.5);
  content: "";
}
.card.loading::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-size: 50px;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  animation: card-loading 2s infinite linear;
}
.card h3 {
  margin-top: 0;
}

.page-header {
  position: relative;
}
.page-header .filters {
  display: flex;
}
.page-header .filter-title {
  display: inline-block;
  margin-right: 7px;
  color: #0f243d;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.page-header label.filter-title {
  margin-bottom: 0;
}
.page-header .filter-submit {
  height: 24px;
  padding: 0 5px;
  color: #0f243d;
}
.page-header .filter {
  display: inline-flex;
}
.page-header .filter-date .input-date {
  display: inline-block;
  height: 24px;
  padding: 0 6px 0 8px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
.page-header .filter-date .input-date label {
  color: #d0d0d0;
  font-size: 12px;
  font-weight: 500;
}
.page-header .filter-date .input-date input[type=text] {
  width: 65px;
  height: 22px;
  margin: 0 5px;
  padding: 0;
  background: none;
  border: none;
  color: #0f243d;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.page-header .filter-date .input-date + .input-date {
  margin-left: 10px;
}
.page-header .filter-list select.select {
  height: 24px;
}
.page-header .filter-search input[type=text],
.page-header .filter-search input[type=search] {
  display: inline-block;
  min-width: 65px;
  height: 24px;
  padding: 0 6px 0 6px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  color: #0f243d;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .page-header .filters {
    margin-top: 10px;
    flex-direction: column;
  }
  .page-header .filter-submit {
    margin-top: 5px;
  }
  .page-header .filter {
    flex-direction: column;
  }
  .page-header .filter + .filter {
    margin-top: 5px;
  }
}
@media (min-width: 768px) {
  .page-header .filters {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    align-items: center;
  }
  .page-header .filter-submit {
    margin-left: 10px;
  }
  .page-header .filter {
    align-items: center;
  }
  .page-header .filter + .filter {
    margin-left: 10px;
  }
}

.fa-rotate-45 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(45deg);
}

:root .fa-rotate-45 {
  filter: none;
}

/**
 * This component is to be used with the Highcharts library.
 *
 * @see https://www.highcharts.com/
 */
.graph-container {
  margin-top: 40px;
}
.graph-container .highcharts-tooltip {
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}

.jGrowl-notification.success {
  background-color: #5cb85c;
}
.jGrowl-notification.info {
  background-color: #5bc0de;
}
.jGrowl-notification.warning {
  background-color: #f0ad4e;
}
.jGrowl-notification.danger {
  background-color: #d9534f;
}

.label-secondary {
  background-color: #3b87e9;
}

@keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
#loader {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0.8;
}
#loader .wrapper {
  position: relative;
  width: 78px;
  height: 78px;
  margin: auto;
  top: 200px;
  transform: translateY(-50%);
}
#loader .wrapper .ball {
  position: absolute;
  width: 74px;
  height: 74px;
  opacity: 0;
  transform: rotate(225deg);
  animation: orbit 6.96s infinite;
}
#loader .wrapper .ball .inner-ball {
  position: absolute;
  width: 10px;
  height: 10px;
  background: black;
  left: 0;
  top: 0;
  border-radius: 10px;
}
#loader .wrapper #ball-1 {
  animation-delay: 1.52s;
}
#loader .wrapper #ball-2 {
  animation-delay: 0.3s;
}
#loader .wrapper #ball-3 {
  animation-delay: 0.61s;
}
#loader .wrapper #ball-4 {
  animation-delay: 0.91s;
}
#loader .wrapper #ball-5 {
  animation-delay: 1.22s;
}

.navmenu-sidebar {
  top: 58px;
  width: 230px;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: 9px 0 11px -11px rgba(0, 0, 0, 0.32);
}
.navmenu-sidebar .dropdown-menu {
  position: relative;
}
.navmenu-sidebar .fas, .navmenu-sidebar .fab, .navmenu-sidebar .far, .navmenu-sidebar .fa,
.navmenu-sidebar .blank {
  margin-right: 15px;
}
.navmenu-sidebar .blank {
  width: 1.25em;
}
.navmenu-sidebar .nav > li > a {
  display: flex;
  align-items: center;
  height: 50px;
  color: #aeaeae;
  font-size: 15px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.navmenu-sidebar .dropdown-menu > li > a {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: calc(1.25em + 30px);
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.navmenu-sidebar .nav > li > a:focus,
.navmenu-sidebar .nav > li > a:hover {
  background-color: #f1f1f1;
  color: #666666;
  font-weight: 600;
}
.navmenu-sidebar .dropdown-menu > li > a:focus,
.navmenu-sidebar .dropdown-menu > li > a:hover {
  background-color: #f1f1f1;
}
.navmenu-sidebar .nav .open > a,
.navmenu-sidebar .nav .open > a:focus,
.navmenu-sidebar .nav .open > a:hover {
  background-color: #fbfbfb;
  color: #3b87e9;
  font-weight: 600;
}
.navmenu-sidebar .nav > li.active > a {
  background-color: #fbfbfb;
  color: #3b87e9;
  font-weight: 600;
}
.navmenu-sidebar .nav > li.active .dropdown-menu > li.active a {
  background-color: #3b87e9;
  color: #ffffff;
}

.pagination {
  margin: 15px 0;
}
.pagination > li > span:hover {
  background-color: #ffffff;
}

.box-body.with-tabs {
  display: flex;
  padding: 0;
}
.box-body.with-tabs ul {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}
.box-body.with-tabs ul a {
  display: block;
  padding: 5px 10px;
  border-right: 1px solid grey;
  color: inherit;
}
.box-body.with-tabs ul a:hover, .box-body.with-tabs ul a:focus {
  text-decoration: none;
}
.box-body.with-tabs ul li.active a {
  background-color: #ffffff;
  border-right-color: transparent;
}
.box-body.with-tabs .tab-content {
  flex: 1;
  background: #ffffff;
}
.box-body.with-tabs .tab-pane {
  padding: 10px;
}
.box-body.with-tabs .chart-container {
  height: 100%;
}

.box-body.with-list-and-chart {
  display: flex;
}
.box-body.with-list-and-chart > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
}
.box-body.with-list-and-chart > .chart-container {
  flex: 1;
  min-height: 350px;
}

.box-body.with-number-and-chart {
  display: flex;
  align-items: center;
}
.box-body.with-number-and-chart .number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
.box-body.with-number-and-chart .chart-container {
  flex: 1;
  height: 100%;
  margin-left: 10px;
}

.statistics-tile {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.switchable-holder {
  height: 14px;
  margin: 0;
  background-color: #e6e6e6;
}
.switchable-holder .switchable-switcher {
  position: relative;
  top: -4.5px;
  width: 21px;
  height: 21px;
}
.switchable-holder.switchable-checked {
  background-color: #295ea1;
}
.switchable-holder.switchable-checked .switchable-switcher {
  background-color: #3b87e9;
  transform: translateX(15px);
}
.switchable-holder.switchable-readonly .switchable-switcher, .switchable-holder.switchable-disabled .switchable-switcher {
  background-color: #aeaeae;
  cursor: not-allowed;
}
.switchable-holder.switchable-readonly.switchable-checked, .switchable-holder.switchable-disabled.switchable-checked {
  background-color: #aeaeae;
}
.switchable-holder.switchable-readonly.switchable-checked .switchable-switcher, .switchable-holder.switchable-disabled.switchable-checked .switchable-switcher {
  background-color: #999999;
}

.main-tabs {
  margin: -20px -20px 20px -20px;
}
.main-tabs > ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  background-color: #0f243d;
  list-style: none;
  overflow-x: auto;
}
.main-tabs > ul li.active a {
  border-bottom-color: #3b87e9;
  color: #ffffff;
  font-weight: 700;
}
.main-tabs > ul a {
  display: inline-block;
  height: 52px;
  padding: 14px 30px;
  border-bottom: 6px solid #0f243d;
  color: rgba(255, 255, 255, 0.65);
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}
.main-tabs > ul a:hover, .main-tabs > ul a:focus {
  border-bottom-color: #142f50;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.main-tabs > .tab-content > .tab-pane {
  padding: 20px;
}

.tabs-container .tab-pane {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-top: none;
}
.tabs-container .tab-pane h2:first-child {
  margin-top: 0;
}

.tiles-container .tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 245px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #3e8df3;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
}
.tiles-container .tile.bg-orange {
  background-color: #FBB03B;
}
.tiles-container .tile.bg-green {
  background-color: #6AAF09;
}
.tiles-container .tile.bg-teal {
  background-color: #10B28D;
}
.tiles-container .tile.bg-dark-teal {
  background-color: #55B477;
}
.tiles-container .tile.bg-blue {
  background-color: #0c94d2;
}
.tiles-container .tile.selectable:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
  cursor: pointer;
}
.tiles-container .tile .tile-header {
  max-height: 100px;
  margin-bottom: 30px;
  padding-bottom: 12px;
  border-bottom: 1px solid #fff;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 16px;
}
.tiles-container .tile .tile-focus {
  font-family: "Roboto Slab", Helvetica, sans-serif;
  font-size: 40px;
  font-weight: bold;
  word-break: break-all;
  word-break: break-word;
}
.tiles-container .tile .tile-text {
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  line-height: 17px;
  word-break: break-all;
  word-break: break-word;
}
.tiles-container .tile .tile-image {
  margin-bottom: 9px;
}
.tiles-container .tile .tile-image .fa,
.tiles-container .tile .tile-image .fas,
.tiles-container .tile .tile-image .far,
.tiles-container .tile .tile-image .fab {
  width: 57px;
  height: 57px;
}
.tiles-container .tile .tile-image .fa::before,
.tiles-container .tile .tile-image .fas::before,
.tiles-container .tile .tile-image .far::before,
.tiles-container .tile .tile-image .fab::before {
  font-size: 57px;
}
.tiles-container .tile a {
  color: inherit;
}
.tiles-container .tile a:hover, .tiles-container .tile a:focus {
  color: inherit;
}

.drag-handle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/ui/dragdrop.png");
  background-image: image-set(url("/assets/images/ui/dragdrop.png") 1x, url("/assets/images/ui/dragdrop@2x.png") 2x, url("/assets/images/ui/dragdrop@3x.png") 3x);
}
.drag-handle:hover {
  cursor: grab;
}

.sortable-handle {
  font-size: 20px;
}
.sortable-handle:hover {
  cursor: grab;
}

.upload-interface .breadcrumb > li + li::before {
  content: "»";
}
.upload-interface table.dataTable {
  margin-top: 0 !important;
}
.upload-interface .dataTables_paginate {
  text-align: center;
}
.upload-interface .dataTables_paginate ul.pagination {
  margin: 15px 0;
}
.upload-interface .files-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.4);
  text-align: center;
}
.upload-interface .files-overlay .drop-zone {
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  border: 2px dashed black;
  border-radius: 5px;
}
.upload-interface .files-overlay i {
  font-size: 40px;
}
.upload-interface .files-overlay p {
  margin-top: 25px;
}
.upload-interface #progress-bar {
  margin-bottom: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1990;
  border-radius: 0;
}
.upload-interface #progress-bar .progress-bar {
  padding: 0 5px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.well {
  padding: 15px 24px;
  border-radius: 10px;
}

.widgets-wrapper .panel {
  display: flex;
  flex-direction: column;
}
.widgets-wrapper .panel-heading {
  border-radius: 0;
  background-color: #2c65ad;
  color: #ffffff;
}
.widgets-wrapper .panel-body {
  flex: 1;
}
.widgets-wrapper .panel-body.no-padding {
  padding: 0;
}
@media (min-width: 1200px) {
  .modal-xl {
    width: 1000px;
  }
}
.modal-fill {
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}

.modal-full-screen {
  width: calc(100% - 60px);
}
.modal-full-screen .modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}
.modal-full-screen .modal-body {
  flex: 1;
  overflow-y: auto;
}

.modal .modal-content {
  border-radius: 0;
}
.modal .modal-header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: none;
}
.modal .modal-header .close {
  order: 1;
  float: none;
  font-size: 30px;
  color: #ffffff;
  opacity: 1;
  filter: none;
}
.modal .modal-header .close:focus, .modal .modal-header .close:hover {
  opacity: 1;
  filter: none;
  text-shadow: 0 0 2px #ddd;
}
.modal .modal-title {
  flex: 1;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 20px;
}
.modal .modal-sub-title {
  margin: 0;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 18px;
}
.modal .modal-steps {
  background-color: #3b87e9;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.modal .modal-steps ul {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 20px 25px 25px 25px;
  overflow-x: auto;
}
.modal .modal-steps ul li {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal .modal-steps ul li::after {
  position: absolute;
  top: calc(100% + 5px);
  display: inline-block;
  width: 9px;
  height: 9px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  content: "";
}
.modal .modal-steps ul li + li {
  margin-left: 15px;
}
.modal .modal-steps .step-number {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.modal .modal-steps .step-name {
  font-size: 12px;
}
.modal .modal-steps .step-active {
  color: #ffffff;
}
.modal .modal-steps .step-active::after {
  background-color: #ffffff;
}
.modal .modal-steps .step-done {
  color: #2c65ad;
}
.modal .modal-steps .step-done::after {
  border-color: #2c65ad;
  background-color: #2c65ad;
}
.modal .modal-steps .step-pending {
  color: rgba(255, 255, 255, 0.2);
}
.modal .modal-steps .has-error::after,
.modal .modal-steps .step-done.has-error::after {
  border-color: #f33636;
  background-color: #f33636;
}
.modal .modal-tabs .nav-tabs > li > a {
  border-radius: 0;
}
.modal .modal-body h4 {
  margin-top: 0;
}
.modal.modal-primary .modal-header, .modal.type-primary .modal-header {
  background-color: #2c65ad;
}
.modal.modal-primary .modal-title, .modal.type-primary .modal-title {
  color: #ffffff;
}
.modal.modal-primary .modal-sub-title, .modal.type-primary .modal-sub-title {
  background-color: #5295ec;
}
.modal.modal-primary .modal-tabs .nav-tabs, .modal.type-primary .modal-tabs .nav-tabs {
  background-color: #5295ec;
}
.modal.modal-primary .modal-tabs .nav-tabs > li:not(.active) > a, .modal.type-primary .modal-tabs .nav-tabs > li:not(.active) > a {
  color: #ffffff;
}
.modal.modal-primary .modal-tabs .nav-tabs > li:not(.active) > a:hover, .modal.type-primary .modal-tabs .nav-tabs > li:not(.active) > a:hover {
  color: #555555;
}
.modal.modal-primary .modal-body .control-label, .modal.type-primary .modal-body .control-label {
  color: #0f243d;
  text-transform: uppercase;
}

.bootstrap-dialog .modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-dialog .bootstrap-dialog-header {
  display: flex;
  flex: 1;
  align-items: center;
}
.bootstrap-dialog .bootstrap-dialog-close-button {
  float: none;
  opacity: 1;
  order: 2;
}
.bootstrap-dialog .bootstrap-dialog-title {
  flex: 1;
}

html {
  position: relative;
  min-height: 100%;
}

.logged-out {
  padding-bottom: 137px;
  background: url("/assets/images/backgrounds/logged-out.jpg") no-repeat;
  background-size: cover;
  color: #ffffff;
  font-family: "Roboto", Helvetica, sans-serif;
}
.logged-out #background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -100;
  min-width: 100%;
  width: auto;
  min-height: 100%;
  height: auto;
}
.logged-out .main-navbar {
  height: 67px;
  background-color: rgba(15, 36, 61, 0.32);
  box-shadow: 0 2px 3px 0 rgba(3, 3, 3, 0.19);
  color: #ffffff;
}
.logged-out .main-navbar .navbar-toggle {
  margin-top: 16.5px;
  margin-bottom: 16.5px;
}
.logged-out .main-navbar .navbar-toggle .icon-bar {
  background-color: #ffffff;
}
.logged-out .main-navbar .navbar-brand {
  height: 67px;
  padding-top: 18.5px;
  padding-bottom: 18.5px;
}
.logged-out .main-navbar .navbar-brand img {
  width: auto;
  max-height: 30px;
}
.logged-out .main-navbar .nav > li > a {
  padding-top: 23.5px;
  padding-bottom: 23.5px;
  color: inherit;
  font-size: 14px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.logged-out .main-navbar .nav > li > a:focus, .logged-out .main-navbar .nav > li > a:hover {
  background: none;
}
@media (max-width: 767px) {
  .logged-out .main-navbar .navbar-collapse {
    background-color: rgba(15, 36, 61, 0.32);
    border-bottom: 1px solid rgba(15, 36, 61, 0.32);
  }
}
.logged-out .main-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 117px;
  background-color: #0f243d;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
.logged-out .main-footer p {
  margin: 0;
}
.logged-out .logo-on-top {
  background-image: url("/assets/images/logos/apps-panel-with-text.png");
  background-image: image-set(url("/assets/images/logos/apps-panel-with-text.png") 1x, url("/assets/images/logos/apps-panel-with-text@2x.png") 2x, url("/assets/images/logos/apps-panel-with-text@3x.png") 3x);
  padding-top: 130px;
  background-repeat: no-repeat;
  background-position: top center;
}
.logged-out .modal {
  color: #333;
}
.logged-out a {
  color: inherit;
}
.logged-out .has-error .help-block[data-temporary=true],
.logged-out .form-errors {
  margin-top: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border: 1px solid #a94442;
}
@media (max-width: 767px) {
  .logged-out {
    padding-bottom: 80px;
  }
  .logged-out .main-footer {
    height: 70px;
  }
}

.logged-in {
  padding-top: 58px;
  background-color: #fbfbfb;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.logged-in .main-navbar {
  height: 58px;
  background-color: #2c65ad;
  color: #ffffff;
}
.logged-in .main-navbar .navbar-toggle {
  margin-top: 12px;
  margin-bottom: 12px;
}
.logged-in .main-navbar .navbar-toggle .icon-bar {
  background-color: #ffffff;
}
.logged-in .main-navbar .navbar-brand {
  height: 58px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.logged-in .main-navbar .navbar-brand img {
  width: auto;
  max-height: 30px;
}
.logged-in .main-navbar .navbar-nav > li > a {
  height: 58px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #142f50;
}
.logged-in .main-navbar .navbar-nav > li > a:focus, .logged-in .main-navbar .navbar-nav > li > a:hover {
  background-color: #10253e;
}
@media (max-width: 767px) {
  .logged-in .main-navbar .navbar-toggle {
    display: inline-flex;
    flex-direction: column;
  }
  .logged-in .main-navbar .navbar-collapse {
    background-color: #2c65ad;
  }
  .logged-in .main-navbar .navbar-form {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none;
  }
}
.logged-in .applications-display {
  min-width: 300px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #3e8df3;
  text-transform: uppercase;
}
.logged-in .applications-display img {
  width: auto;
  height: auto;
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
  overflow: hidden;
}
.logged-in .applications-display .application-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  line-height: 1;
}
.logged-in .applications-display img + .application-wrapper {
  margin-left: 5px;
}
.logged-in .applications-display .application-name {
  font-size: 13.7px;
  font-weight: 600;
}
.logged-in .applications-display .application-environment {
  font-size: 12px;
  font-weight: 300;
}
.logged-in .applications-display.navbar-text {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 15px;
}
.logged-in .applications-display .select2-selection__clear {
  display: none;
}
.logged-in .applications-display .select2-selection__rendered {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 5px;
  padding-right: 23px;
  color: #ffffff;
}
.logged-in .applications-display .select2-selection__placeholder {
  margin-left: 5px;
  color: inherit;
}
.logged-in .applications-display .select2-selection__arrow {
  background-image: url("/assets/images/ui/caret-white.png");
  background-image: image-set(url("/assets/images/ui/caret-white.png") 1x, url("/assets/images/ui/caret-white@2x.png") 2x, url("/assets/images/ui/caret-white@3x.png") 3x);
  top: 7px;
  right: 3px;
  background-repeat: no-repeat;
  background-position: center center;
}
.logged-in .applications-display .select2-selection__arrow b {
  display: none;
}
.logged-in .applications-display[aria-expanded=true] .select2-selection__arrow {
  background-image: url("/assets/images/ui/caret-white-up.png");
  background-image: image-set(url("/assets/images/ui/caret-white-up.png") 1x, url("/assets/images/ui/caret-white-up@2x.png") 2x, url("/assets/images/ui/caret-white-up@3x.png") 3x);
}
.logged-in .select2-container--default.select2-container--open.select2-container--below .select2-selection--single.applications-display,
.logged-in .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple.applications-display {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.logged-in .applications-dropdown {
  top: 20px;
  background-color: #0a1626;
  box-shadow: 1.5px 4.8px 5px 0 rgba(0, 0, 0, 0.22);
  border-radius: 4px;
}
.logged-in .applications-dropdown::before {
  position: absolute;
  top: -26px;
  right: 75px;
  content: "";
  font-family: "Font Awesome 5 Free";
  color: #0a1626;
  font-size: 30px;
  font-weight: 900;
}
.logged-in .applications-dropdown .select2-search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  padding-left: 54px;
  padding-right: 15px;
  background-image: url("/assets/images/ui/icon-white-magnifier.png");
  background-image: image-set(url("/assets/images/ui/icon-white-magnifier.png") 1x, url("/assets/images/ui/icon-white-magnifier@2x.png") 2x, url("/assets/images/ui/icon-white-magnifier@3x.png") 3x);
  background-repeat: no-repeat;
  background-position: left 20px center;
}
.logged-in .applications-dropdown .select2-search input {
  background: none;
  border: solid rgba(255, 255, 255, 0.39);
  border-width: 0 0 1px 0;
  color: #ffffff;
  font-size: 16px;
}
.logged-in .applications-dropdown .select2-search input::placeholder {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.35);
}
.logged-in .applications-dropdown .select2-search input:focus {
  outline: none;
}
.logged-in .applications-dropdown .select2-results {
  background-color: #090f18;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
}
.logged-in .applications-dropdown .select2-results__option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  padding: 0 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}
.logged-in .applications-dropdown .select2-results__option[aria-selected=true] {
  background-color: transparent;
  color: #5995d9;
}
.logged-in .applications-dropdown .select2-results__option--highlighted, .logged-in .applications-dropdown .select2-results__option--highlighted[aria-selected=true] {
  background-color: #091422;
  color: #5995d9;
}
.logged-in .applications-dropdown img {
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.logged-in .applications-dropdown .application-name, .logged-in .applications-dropdown .application-environment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.logged-in .applications-dropdown .application-name {
  font-weight: 600;
}
.logged-in .applications-dropdown .application-environment {
  margin-left: 5px;
  font-weight: 300;
}
@media (max-width: 767px) {
  .logged-in .applications-display {
    min-width: auto;
  }
}
.logged-in .profile-container {
  margin-top: 0;
  margin-bottom: 0;
}
.logged-in .profile-account {
  min-width: 220px;
}
.logged-in .profile-account a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
}
.logged-in .profile-name {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 15px;
}
.logged-in .profile-email {
  color: #ffffff;
}
.logged-in .profile-name + .profile-email {
  color: rgba(255, 255, 255, 0.57);
  font-size: 12px;
}
.logged-in .profile-logout a {
  width: 58px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/ui/icon-quit.png");
  background-image: image-set(url("/assets/images/ui/icon-quit.png") 1x, url("/assets/images/ui/icon-quit@2x.png") 2x, url("/assets/images/ui/icon-quit@3x.png") 3x);
}
@media (max-width: 767px) {
  .logged-in .profile-container {
    display: flex;
  }
  .logged-in .profile-account {
    flex: 1;
    min-width: auto;
  }
}
.logged-in .main-container {
  min-height: calc(100vh - 58px);
}
.logged-in #wrapper {
  margin-left: 230px;
  padding: 20px 20px 50px 20px;
}
.logged-in #tabs-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #0f243d;
  width: calc(100% + 40px);
  margin: -20px -20px 20px -20px;
  overflow-x: auto;
}
.logged-in #tabs-container .tab {
  display: inline-block;
  height: 52px;
  padding: 14px 30px;
  border-bottom: 6px solid #0f243d;
  color: rgba(255, 255, 255, 0.65);
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}
.logged-in #tabs-container .tab.selected {
  border-bottom-color: #3b87e9;
  color: #ffffff;
  font-weight: 700;
}
.logged-in #tabs-container a {
  color: inherit;
}
.logged-in #tabs-container a:hover, .logged-in #tabs-container a:focus {
  border-bottom-color: #142f50;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.logged-in .main-footer {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
@media (max-width: 767px) {
  .logged-in #wrapper {
    margin-left: 0;
    padding-bottom: 85px;
  }
  .logged-in .main-footer {
    left: 15px;
    text-align: center;
  }
}

.error-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("/assets/images/backgrounds/logged-out.jpg") no-repeat center center;
  background-size: cover;
  color: #ffffff;
  font-family: "Roboto", Helvetica, sans-serif;
}
.error-404 h1 {
  color: #ffffff;
}
.error-404 .container {
  display: flex;
  flex-direction: column;
}
.error-404 .error-code {
  font-size: 70px;
  font-family: "Open Sans", Helvetica, sans-serif;
  text-align: center;
}
.error-404 .error-explanation {
  text-align: center;
}
@media (min-width: 768px) {
  .error-404 .container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .error-404 .error-explanation {
    margin-left: 15px;
  }
}

.account-profile h2 {
  margin-top: 0;
}

.administration table.dataTable td,
.administration table.dataTable th {
  box-sizing: border-box;
}
.administration .application-result {
  display: flex;
  align-items: center;
}
.administration .application-result img {
  max-height: 40px;
  width: auto;
  margin-right: 15px;
}
.administration .application-result .environment {
  margin-left: 5px;
  color: #666666;
}

.administration.continuous-integration.settings .input-firebase-app-id {
  min-width: 250px;
}
.administration.continuous-integration.settings .column-environment {
  min-width: 375px;
}
.administration.continuous-integration.settings .column-environment ~ th {
  width: 50%;
}
.administration.continuous-integration.settings .cell-label-production {
  width: 0;
}

.administration #modal-add-access tbody:not(:empty) + tfoot {
  display: none;
}
.administration #modal-permissions .table > tbody > tr > td {
  vertical-align: top;
}
.administration.global-users .selection-applications .select2-selection__rendered,
.administration.global-users .dropdown-applications .select2-results__option {
  display: flex;
  align-items: center;
  line-height: 1;
}
.administration.global-users .selection-applications .select2-selection__rendered {
  height: 100%;
}
.administration.global-users .selection-applications img {
  max-width: 28px;
  max-height: 28px;
  margin-right: 5px;
}
.administration.global-users .selection-applications small {
  margin-left: 5px;
}
.administration.global-users .dropdown-applications img {
  max-width: 32px;
  max-height: 32px;
  margin-right: 5px;
}
.administration.global-users .selection-groups .select2-selection__rendered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dashboard .dashboard-container {
  display: flex;
  min-height: calc(100vh - 180px);
}
.dashboard .dashboard-container strong {
  font-size: 30px;
}
.dashboard .health-container .health {
  display: inline-block;
  height: 10px;
}
.dashboard .health-container .health[data-rating="1"] {
  background: #ff0000;
}
.dashboard .health-container .health[data-rating="2"] {
  background: #ff1515;
}
.dashboard .health-container .health[data-rating="3"] {
  background: #ff3434;
}
.dashboard .health-container .health[data-rating="4"] {
  background: #e36c1c;
}
.dashboard .health-container .health[data-rating="5"] {
  background: #ff6700;
}
.dashboard .health-container .health[data-rating="6"] {
  background: #ff7c25;
}
.dashboard .health-container .health[data-rating="7"] {
  background: #d3b505;
}
.dashboard .health-container .health[data-rating="8"] {
  background: #9ba300;
}
.dashboard .health-container .health[data-rating="9"] {
  background: #00a800;
}
.dashboard .health-container .health[data-rating="10"] {
  background: #00ff00;
}
@supports (display: grid) {
  .dashboard .health-container {
    display: grid;
    grid-template-columns: 30px repeat(auto-fit, minmax(10px, 1fr));
    grid-column-gap: 5px;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .dashboard .dashboard-grid {
    display: grid;
    grid-gap: 15px;
  }
  .dashboard .dashboard-grid .card {
    margin-bottom: 0;
  }
  .dashboard .dashboard-grid.type-a {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "chart chart chart chart chart chart chart chart chart chart chart chart" "health health health health stores stores stores stores feedback feedback feedback feedback" "session-times session-times session-times sessions-per-user sessions-per-user sessions-per-user downloads-evolution downloads-evolution downloads-evolution ratings-evolution ratings-evolution ratings-evolution";
  }
  .dashboard .dashboard-grid.type-b {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "chart chart chart" "health session-times sessions-per-user";
  }
  .dashboard .dashboard-grid.type-b .card[data-card=stores],
.dashboard .dashboard-grid.type-b .card[data-card=feedback],
.dashboard .dashboard-grid.type-b .card[data-card=downloads-evolution],
.dashboard .dashboard-grid.type-b .card[data-card=ratings-evolution] {
    display: none;
  }
  .dashboard .dashboard-grid.type-c {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "chart chart chart chart chart chart" "health health stores stores feedback feedback" "session-times session-times session-times sessions-per-user sessions-per-user sessions-per-user";
  }
  .dashboard .dashboard-grid.type-c .card[data-card=downloads-evolution],
.dashboard .dashboard-grid.type-c .card[data-card=ratings-evolution] {
    display: none;
  }
  .dashboard .dashboard-grid.type-d {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "chart chart" "health health" "session-times sessions-per-user";
  }
  .dashboard .dashboard-grid.type-d .card[data-card=stores],
.dashboard .dashboard-grid.type-d .card[data-card=feedback],
.dashboard .dashboard-grid.type-d .card[data-card=downloads-evolution],
.dashboard .dashboard-grid.type-d .card[data-card=ratings-evolution] {
    display: none;
  }
  .dashboard .card[data-card=chart] {
    grid-area: chart;
  }
  .dashboard .card[data-card=health] {
    grid-area: health;
  }
  .dashboard .card[data-card=stores] {
    grid-area: stores;
  }
  .dashboard .card[data-card=feedback] {
    grid-area: feedback;
  }
  .dashboard .card[data-card=session-times] {
    grid-area: session-times;
  }
  .dashboard .card[data-card=sessions-per-user] {
    grid-area: sessions-per-user;
  }
  .dashboard .card[data-card=downloads-evolution] {
    grid-area: downloads-evolution;
  }
  .dashboard .card[data-card=ratings-evolution] {
    grid-area: ratings-evolution;
  }
}
.dashboard .statistics-container {
  flex: 1;
}
.dashboard .activity-stream {
  width: 350px;
  margin: -20px -20px -50px 20px;
  padding: 15px;
  background-color: #eeeeee;
}
.dashboard .activity-stream .card {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 40px 1fr;
  -ms-grid-columns: 40px 15px 1fr;
  grid-template-rows: auto auto;
  -ms-grid-rows: 1fr 1fr;
}
.dashboard .activity-stream .card:last-child {
  margin-bottom: 0;
}
.dashboard .activity-stream i {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: span 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  font-size: 20px;
}
.dashboard .activity-stream .meta {
  align-self: end;
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}
.dashboard .activity-stream .text {
  align-self: start;
  -ms-grid-column: 3;
  -ms-grid-row: 2;
}
@media (min-width: 992px) {
  .dashboard .main-footer {
    right: 365px;
  }
}

.develop.api.console .tabs-list {
  display: flex;
  flex-wrap: wrap;
}
.develop.api.console .tabs-list > li {
  float: none;
}
.develop.api.console .tabs-list > li > button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.develop.api.console .tabs-list > li:not(:last-child) > button {
  margin-right: 2px;
}
.develop.api.console .filters-container {
  padding-top: 15px;
  background-color: #ffffff;
}
.develop.api.console .errors-container {
  margin-bottom: 15px;
  background-color: #ffffff;
}
.develop.api.console .errors-container .response-success {
  border-left: 2px solid transparent;
  border-left-color: #5ec938;
}
.develop.api.console .errors-container .response-error {
  border-left: 2px solid transparent;
  border-left-color: #f33636;
}
.develop.api.console .table-container ul {
  padding-left: 20px;
}
.develop.api.console .console-container {
  border: solid #dddddd;
  border-width: 0 1px 1px 1px;
}
.develop.api.console .console-container tbody .device-uid {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.develop.api.console .console-container .even {
  background-color: #f9f9f9;
}
.develop.api.console .console-container tr[data-toggle=row] {
  cursor: pointer;
}
.develop.api.console .console-container .date-time {
  text-align: center;
}
.develop.api.console .console-container .action {
  max-width: 800px;
}
.develop.api.console .console-container .os {
  white-space: nowrap;
}
.develop.api.console .console-container .result {
  text-align: center;
}
.develop.api.console .console-container .date-time,
.develop.api.console .console-container .full-uri,
.develop.api.console .console-container .details-container {
  border-left: 2px solid transparent;
}
.develop.api.console .console-container .full-uri {
  padding: 8px 15px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  word-break: break-all;
  word-break: break-word;
}
.develop.api.console .console-container .details-container {
  padding: 8px 0;
}
.develop.api.console .console-container .response-success .date-time {
  border-left-color: #5ec938;
}
.develop.api.console .console-container .response-success .full-uri,
.develop.api.console .console-container .response-success .details-container {
  border-left-color: rgba(94, 201, 56, 0.5);
}
.develop.api.console .console-container .response-success.response-slow .date-time {
  border-left-color: #f9c039;
}
.develop.api.console .console-container .response-success.response-slow .full-uri, .develop.api.console .console-container .response-success.response-slow .details-container {
  border-left-color: rgba(249, 192, 57, 0.5);
}
.develop.api.console .console-container .response-error .date-time {
  border-left-color: #f33636;
}
.develop.api.console .console-container .response-error .full-uri,
.develop.api.console .console-container .response-error .details-container {
  border-left-color: rgba(243, 54, 54, 0.5);
}
.develop.api.console .console-container .response-error.response-slow {
  border-left-color: #180712;
}
.develop.api.console .console-container .response-error.response-slow .full-uri, .develop.api.console .console-container .response-error.response-slow .details-container {
  border-left-color: rgba(24, 7, 18, 0.5);
}
.develop.api.console .console-container .block-scrollable {
  max-height: 340px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-all;
}
.develop.api.console .console-container .logs-container {
  color: #333333;
  font-size: 13px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.develop.api.console .console-container .level-notice {
  color: #f0ad4e;
}
.develop.api.console .console-container .level-warning {
  color: #ee8012;
}
.develop.api.console .console-container .level-error,
.develop.api.console .console-container .level-critical,
.develop.api.console .console-container .level-alert,
.develop.api.console .console-container .level-emergency {
  color: #f33636;
}
.develop.api.console .console-container a[data-action=copy] {
  cursor: pointer;
}
.develop.api.console .console-container .table {
  margin-bottom: 0;
}
.develop.api.console .console-container h3 {
  margin-top: 0;
  color: #3b87e9;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.develop.api.console .console-container h4 {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.develop.api.console .console-container h4 small {
  font-size: 90%;
}

.develop.api.doc-and-run .swagger-ui .wrapper {
  max-width: none;
  padding: 0;
}
.develop.api.doc-and-run .swagger-ui .topbar {
  padding: 5px;
  background: #0f243d;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.develop.api.doc-and-run .swagger-ui .topbar .topbar-wrapper {
  flex-wrap: wrap;
}
.develop.api.doc-and-run .swagger-ui .topbar a,
.develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper {
  flex: 0 0 100%;
  max-width: initial;
}
.develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper {
  margin-left: auto;
}
.develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper .select-label {
  flex-direction: column;
}
.develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper .select-label span {
  margin-bottom: 5px;
  padding: 0;
}
.develop.api.doc-and-run .swagger-ui .information-container {
  padding: 0 5px;
  background: #0f243d;
}
.develop.api.doc-and-run .swagger-ui .info {
  margin: 0;
}
.develop.api.doc-and-run .swagger-ui .info .title {
  display: none;
  color: #f0f0f0;
}
.develop.api.doc-and-run .swagger-ui .info .title small pre {
  background: none;
  border: none;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.develop.api.doc-and-run .swagger-ui .info hgroup.main {
  margin: 0;
}
.develop.api.doc-and-run .swagger-ui .info .description {
  margin: 0;
}
.develop.api.doc-and-run .swagger-ui .scheme-container {
  padding: 0 5px 5px 5px;
  background: #0f243d;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: none;
  color: #f0f0f0;
}
.develop.api.doc-and-run .swagger-ui .scheme-container .schemes > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.develop.api.doc-and-run .swagger-ui .servers-title {
  margin-bottom: 5px;
  text-align: center;
}
.develop.api.doc-and-run .swagger-ui .servers > label {
  width: 100%;
  margin: 0;
}
.develop.api.doc-and-run .swagger-ui .servers > label select {
  width: 100%;
}
@media (min-width: 768px) {
  .develop.api.doc-and-run .swagger-ui .topbar a,
.develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper {
    flex: initial;
  }
  .develop.api.doc-and-run .swagger-ui .topbar a {
    max-width: 300px;
  }
  .develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper {
    max-width: 600px;
  }
  .develop.api.doc-and-run .swagger-ui .information-container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .develop.api.doc-and-run .swagger-ui .scheme-container .schemes > div {
    flex-direction: row;
  }
  .develop.api.doc-and-run .swagger-ui .servers-title {
    margin-right: 5px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper .select-label {
    flex-direction: row;
  }
  .develop.api.doc-and-run .swagger-ui .topbar .download-url-wrapper .select-label span {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .develop.api.doc-and-run .swagger-ui.swagger-container > .swagger-ui > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
  }
  .develop.api.doc-and-run .swagger-ui .information-container,
.develop.api.doc-and-run .swagger-ui .scheme-container {
    width: 50%;
    margin-bottom: 15px;
  }
  .develop.api.doc-and-run .swagger-ui .information-container {
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    padding-bottom: 5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
  }
  .develop.api.doc-and-run .swagger-ui .scheme-container {
    padding-top: 15px;
    border-bottom-left-radius: 0;
  }
  .develop.api.doc-and-run .swagger-ui .scheme-container .schemes > div {
    justify-content: flex-end;
  }
}

.develop-api-cache .tiles-container .loading {
  background-color: #142f50;
}
.develop-api-cache .tiles-container .selected {
  background-color: #2c65ad;
}
.develop-api-cache #form-rules .table-indexed {
  counter-reset: rules;
}
.develop-api-cache #form-rules .table-indexed tr:not(.row-second) td:first-of-type {
  text-align: center;
}
.develop-api-cache #form-rules .table-indexed tr:not(.row-second) td:first-of-type::before {
  counter-increment: rules;
  content: counter(rules);
}
.develop-api-cache #form-rules .row-first td a {
  display: inline-block;
  width: 23px;
  height: 23px;
}
.develop-api-cache #form-rules .headers-list {
  margin-bottom: 15px;
}
.develop-api-cache #form-rules .headers-list .input-group + .input-group {
  margin-top: 15px;
}

.develop-api-security .domains-container {
  margin-top: 7px;
}
.develop-api-security #form-rules tbody {
  counter-reset: rules;
}
.develop-api-security #form-rules tbody tr td:first-of-type {
  text-align: center;
}
.develop-api-security #form-rules tbody tr td:first-of-type::before {
  counter-increment: rules;
  content: counter(rules);
}

.develop.api.environment .diagnostic-passed,
.develop.api.environment .diagnostic-missing,
.develop.api.environment .diagnostic-failed {
  font-weight: bold;
}
.develop.api.environment .diagnostic-passed {
  color: #4bd16d;
}
.develop.api.environment .diagnostic-missing {
  color: #edd000;
}
.develop.api.environment .diagnostic-failed {
  color: #f33636;
}
.develop.api.environment thead .end + .start {
  border-left: 1px solid #ddd;
}
.develop.api.environment tr[data-file] .end + .start,
.develop.api.environment tr[data-table] .end + .start {
  border-left: 1px solid #f3f3f3;
}
.develop.api.environment tr[data-file].has-diff td,
.develop.api.environment tr[data-table].has-diff td {
  cursor: pointer;
}
.develop.api.environment tr[data-file].has-diff td:last-child .fas,
.develop.api.environment tr[data-table].has-diff td:last-child .fas {
  transition: transform 0.3s ease;
}
.develop.api.environment tr[data-file].diff-shown td:last-child .fas,
.develop.api.environment tr[data-table].diff-shown td:last-child .fas {
  transform: rotate(90deg);
}
.develop.api.environment tr[data-of-table] > td {
  padding: 0;
}
.develop.api.environment tr[data-of-table] table {
  margin-bottom: 0;
  border-top: none;
}
.develop.api.environment tr[data-of-table] table tr:last-child td {
  border-bottom: none;
}
.develop.api.environment tr[data-of-table] th,
.develop.api.environment tr[data-of-table] td,
.develop.api.environment tr[data-file-diff-of] td {
  background-color: #ededed;
  border-color: #dddddd;
  font-size: 13px;
}
.develop.api.environment tr[data-of-table] .end + .start {
  border-left: 1px solid #dddddd;
}
.develop.api.environment .diff-wrapper {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
.develop.api.environment .diff-wrapper .old,
.develop.api.environment .diff-wrapper .new {
  font-family: "Courier New", sans-serif;
  white-space: pre-wrap;
}
.develop.api.environment .diff-wrapper .n-old,
.develop.api.environment .diff-wrapper .n-new {
  padding: 1px 5px;
  text-align: right;
}
.develop.api.environment .diff-wrapper ins {
  text-decoration: none;
}
.develop.api.environment .diff-wrapper .change-eq + .change-eq {
  border-top: 1px dashed #666666;
}
.develop.api.environment .diff-wrapper .change-rep .old {
  background: #fe9;
}
.develop.api.environment .diff-wrapper .change-rep .new {
  background: #fd8;
}
.develop.api.environment .diff-wrapper .change-rep ins,
.develop.api.environment .diff-wrapper .change-rep del {
  background: #fc0;
}
.develop.api.environment .diff-wrapper .change-rep del {
  text-decoration: none;
}
.develop.api.environment .diff-wrapper .change-ins .old {
  background: #f88;
}
.develop.api.environment .diff-wrapper .change-ins .new {
  background: #faa;
}
.develop.api.environment .diff-wrapper .change-ins ins,
.develop.api.environment .diff-wrapper .change-ins del {
  background: #e99;
}
.develop.api.environment .diff-wrapper .change-del .old {
  background: #faa;
}
.develop.api.environment .diff-wrapper .change-del .new {
  background: #f88;
}
.develop.api.environment .diff-wrapper .change-del ins,
.develop.api.environment .diff-wrapper .change-del del {
  background: #e99;
}

.develop-data-files .files-header {
  display: flex;
  margin-bottom: 15px;
}
.develop-data-files .files-header > * + * {
  margin-left: 15px;
}
.develop-data-files .files-container.as-list .header, .develop-data-files .files-container.as-list .loader, .develop-data-files .files-container.as-list .folder, .develop-data-files .files-container.as-list .file {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.develop-data-files .files-container.as-list .folder + .folder,
.develop-data-files .files-container.as-list .folder + .file,
.develop-data-files .files-container.as-list .file + .file {
  border-top: 1px solid #f3f3f3;
}
.develop-data-files .files-container.as-list .header {
  background-color: #f3f3f3;
  color: #666666;
  font-size: 14px;
  font-weight: bold;
}
.develop-data-files .files-container.as-list .icon,
.develop-data-files .files-container.as-list .name,
.develop-data-files .files-container.as-list .size,
.develop-data-files .files-container.as-list .last-modified-at,
.develop-data-files .files-container.as-list .permissions,
.develop-data-files .files-container.as-list .actions {
  display: inline-flex;
  align-items: center;
  height: inherit;
  padding: 5px 5px;
}
.develop-data-files .files-container.as-list .icon {
  justify-content: center;
  width: 40px;
  font-size: 20px;
}
.develop-data-files .files-container.as-list .name {
  flex: 1;
}
.develop-data-files .files-container.as-list .size {
  width: 100px;
}
.develop-data-files .files-container.as-list .last-modified-at {
  width: 180px;
}
.develop-data-files .files-container.as-list .permissions {
  width: 100px;
}
.develop-data-files .files-container.as-list .actions {
  justify-content: center;
  width: 70px;
}
.develop-data-files .files-container.as-list .actions a + a {
  margin-left: 5px;
}
.develop-data-files .files-container.as-list .actions .fa, .develop-data-files .files-container.as-list .actions .fas, .develop-data-files .files-container.as-list .actions .fab {
  font-size: 20px;
}
.develop-data-files .files-container.as-list progress {
  width: 450px;
}

.develop.data.text-manager #wrapper {
  padding-bottom: 70px;
}
.develop.data.text-manager .search-container {
  flex: 1;
  max-width: 300px;
}
.develop.data.text-manager .texts-container > tbody > tr > td {
  vertical-align: top;
}
.develop.data.text-manager .texts-container > tfoot > tr > th {
  border-top-color: #f3f3f3;
}
.develop.data.text-manager .texts-container input,
.develop.data.text-manager .texts-container textarea {
  min-width: 250px;
}
.develop.data.text-manager .texts-container .new-key {
  background-color: #5bc0de;
}
.develop.data.text-manager .texts-container .duplicated-key {
  background-color: #f0ad4e;
}
.develop.data.text-manager .texts-container .conflict {
  background-color: #d9534f;
}
.develop.data.text-manager .language-container {
  display: flex;
  align-items: center;
}
.develop.data.text-manager .language-container span {
  margin-right: 5px;
}
.develop.data.text-manager .language-container a {
  font-size: 16px;
}
.develop.data.text-manager .language-container a:first-of-type {
  margin-left: auto;
}
.develop.data.text-manager .language-container a ~ a {
  margin-left: 10px;
}
.develop.data.text-manager table.dataTable {
  margin: 0 !important;
}
.develop.data.text-manager .btn-floating.btn-primary {
  right: 70px;
  bottom: 15px;
}
.develop.data.text-manager .btn-floating.btn-success {
  right: 15px;
  bottom: 15px;
}
.develop.data.text-manager #modal-import-csv .table {
  margin: 0;
}

.develop.sdk.download .tiles-container {
  display: flex;
  flex-wrap: wrap;
  margin: -7.5px;
}
.develop.sdk.download .tile {
  width: 100%;
  min-height: 245px;
  margin: 7.5px;
  padding: 15px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
}
.develop.sdk.download .tile.tile-android {
  background-color: #a4c639;
}
.develop.sdk.download .tile.tile-ios {
  background-color: #9a9a9a;
}
.develop.sdk.download .tile.tile-windows-phone {
  background-color: #0993d2;
}
.develop.sdk.download .tile-image {
  margin-bottom: 15px;
}
.develop.sdk.download .tile-image .fas,
.develop.sdk.download .tile-image .far,
.develop.sdk.download .tile-image .fab {
  width: 57px;
  height: 57px;
}
.develop.sdk.download .tile-image .fas::before,
.develop.sdk.download .tile-image .far::before,
.develop.sdk.download .tile-image .fab::before {
  font-size: 57px;
}
.develop.sdk.download .tile-focus {
  font-family: "Roboto Slab", Helvetica, sans-serif;
  font-size: 50px;
  font-weight: bold;
  line-height: 1;
}
.develop.sdk.download .tile-text {
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  line-height: 17px;
}
.develop.sdk.download .tile a {
  color: inherit;
}
.develop.sdk.download .tile a:hover, .develop.sdk.download .tile a:focus {
  color: inherit;
}
@media (min-width: 992px) {
  .develop.sdk.download .tile {
    width: calc((100% - 45px) / 3);
  }
}

.develop.sdk.settings .control-label {
  color: #666666;
  font-size: 14px;
  font-weight: bold;
}
.develop.sdk.settings input[type=text],
.develop.sdk.settings input[type=password],
.develop.sdk.settings input[type=email],
.develop.sdk.settings input[type=number] {
  height: 32px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(102, 102, 102, 0.06);
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #666666;
  font-size: 12px;
}
.develop.sdk.settings .well {
  font-size: 12px;
}
.develop.sdk.settings .well .control-label {
  text-transform: uppercase;
}
.develop.sdk.settings .panel {
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
.develop.sdk.settings .panel-heading {
  display: flex;
  align-items: center;
  padding: 20px 18px;
  color: #666666;
  font-size: 12px;
}
.develop.sdk.settings .panel-heading label {
  margin: 0 25px 0 25px;
  font-size: 12px;
  font-weight: normal;
}
.develop.sdk.settings .panel-heading button {
  margin-left: auto;
}
.develop.sdk.settings .panel-title {
  color: #666666;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.develop.sdk.settings .panel-toggle {
  width: 23px;
  height: 23px;
  padding: 0;
  background-color: #0f243d;
  border: none;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
}
.develop.sdk.settings .panel-body {
  background-color: rgba(0, 0, 0, 0.04);
}
.develop.sdk.settings .panel-body .form-group:last-child {
  margin-bottom: 0;
}
.develop.sdk.settings .panel-body .switchable-wrapper {
  padding-top: 9px;
}

.distribute.your-app-store #modal-qr-code .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.distribute.your-app-store #modal-qr-code img {
  max-width: 100%;
}

.interact.polls [data-field=filter] .input-list > .input-group {
  margin-bottom: 15px;
}

.interact.push.form .form-and-preview-container {
  display: flex;
  min-height: calc(100vh - 180px);
}
.interact.push.form .form-container {
  flex: 1;
}
.interact.push.form .form-container .radio ~ .radio {
  margin-top: -5px;
}
.interact.push.form .form-container .radio + [data-related-to] {
  margin: 5px 0 10px 0;
  padding-left: 20px;
}
@media (max-width: 479px) {
  .interact.push.form .form-container .input-group-flex .input-group + .input-group,
.interact.push.form .form-container .input-group-flex .input-group-flex + .input-group-flex {
    margin-top: 5px;
  }
}
@media (min-width: 480px) {
  .interact.push.form .form-container .input-group-flex .input-group + .input-group,
.interact.push.form .form-container .input-group-flex .input-group-flex + .input-group-flex {
    margin-left: 5px;
  }
}
.interact.push.form .preview-container {
  display: none;
  width: 350px;
  margin: -20px -20px -50px 20px;
  background-color: #eeeeee;
}
.interact.push.form .preview-inner {
  position: sticky;
  top: 58px;
  padding: 15px 0;
}
.interact.push.form .os-list {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 25px;
}
.interact.push.form .os-list a:hover {
  text-decoration: none;
}
.interact.push.form .os-list a ~ a {
  margin-left: 25px;
}
.interact.push.form .carousel {
  height: 307px;
}
.interact.push.form .carousel-inner > .item > img {
  margin: 0 auto;
}
.interact.push.form .carousel-indicators {
  margin-bottom: 0;
  bottom: 1px;
}
.interact.push.form .carousel-indicators li {
  border-color: #333333;
}
.interact.push.form .carousel-indicators .active {
  background-color: #333333;
}
.interact.push.form .carousel-control {
  background-image: none;
}
.interact.push.form .notification-metadata {
  padding: 0 15px;
  text-align: center;
}
.interact.push.form .notification-metadata .fa-spin {
  margin-right: 5px;
}
@media (min-width: 992px) {
  .interact.push.form .preview-container {
    display: block;
  }
  .interact.push.form .main-footer {
    right: 365px;
  }
}

.interact.push.history .form-header {
  margin-top: 0;
}
.interact.push.history table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 15px !important;
}
.interact.push.history div.dataTables_wrapper div.dataTables_paginate {
  text-align: center;
}

.interact.push.settings .pre-scrollable {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.interact.push.settings .certificates-list + .help-block {
  padding: 10px;
  border-top: 1px solid #dddddd;
}
.interact.push.settings .certificate-group {
  margin: 0;
  padding: 10px 10px 0 10px;
  border-top: 1px solid #dddddd;
}
.interact.push.settings #ios-footer {
  border-top: 1px solid #dddddd;
}

.security h1, .security .h1,
.security h2, .security .h2 {
  text-transform: uppercase;
}
.security h1, .security .h1 {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  overflow: hidden;
}
.security h1::after, .security .h1::after {
  display: inline-block;
  flex: 1;
  height: 1px;
  margin-left: 30px;
  background-color: rgba(255, 255, 255, 0.14);
  content: "";
}
.security h2, .security .h2 {
  font-size: 16px;
}
.security section + section {
  margin-top: 40px;
}
.security .help-block {
  margin-top: 5px;
}
.security #error-container:not(:empty) {
  margin: 10px 0;
}
.security .buttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.security .open-id-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
}
.security .open-id-container .btn-open-id {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 991px) {
  .security .row .col-md-6 + .col-md-6 {
    margin-top: 10px;
  }
}
@media (min-width: 480px) {
  .security .open-id-container .btn-open-id {
    width: calc((100% - 30px) / 3);
  }
}
@supports (display: grid) {
  .security .open-id-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .security .open-id-container .btn-open-id {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  @media (min-width: 480px) {
    .security .open-id-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.security.password-reset h1 + p {
  margin-top: 20px;
}

.understand .filters-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px 10px -5px;
}
.understand .filters-container > * {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.understand .filters-container button[type=submit] {
  margin-left: auto;
}
.understand .has-tooltip {
  border-bottom: 1px dotted;
}
.understand .has-tooltip.text-success {
  border-bottom-color: #5cb85c;
}
.understand .has-tooltip.text-warning {
  border-bottom-color: #f0ad4e;
}
.understand .has-tooltip.text-danger {
  border-bottom-color: #d9534f;
}
.understand .grid-container {
  display: -ms-grid;
}
.understand .grid-container .card {
  margin: 0;
}
.understand .grid-container + .grid-container {
  margin-top: 15px;
}
.understand .flex-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .understand .flex-container {
    flex-direction: row;
  }
}
.understand.analytics .grid-container {
  -ms-grid-columns: 100%;
}
.understand.analytics .grid-container.top-container {
  -ms-grid-rows: auto 15px auto;
}
.understand.analytics .grid-container [data-card=most-called] {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.understand.analytics .grid-container [data-card=total] {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.understand.analytics .grid-container.pies-container {
  -ms-grid-rows: auto 15px auto 15px auto 15px auto 15px auto 15px auto;
}
.understand.analytics .grid-container [data-card=methods] {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.understand.analytics .grid-container [data-card=http-codes] {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.understand.analytics .grid-container [data-card=os] {
  -ms-grid-column: 1;
  -ms-grid-row: 5;
}
.understand.analytics .grid-container [data-card=cache-states] {
  -ms-grid-column: 1;
  -ms-grid-row: 7;
}
.understand.analytics .grid-container [data-card=response-times] {
  -ms-grid-column: 1;
  -ms-grid-row: 9;
}
.understand.analytics .grid-container [data-card=response-sizes] {
  -ms-grid-column: 1;
  -ms-grid-row: 11;
}
@media (min-width: 992px) {
  .understand.analytics .grid-container.top-container {
    -ms-grid-columns: 30% 15px calc(70% - 15px);
    -ms-grid-rows: auto;
  }
  .understand.analytics .grid-container [data-card=most-called] {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  .understand.analytics .grid-container [data-card=total] {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
  .understand.analytics .grid-container.pies-container {
    -ms-grid-columns: calc((100% - 30px) / 3) 15px calc((100% - 30px) / 3) 15px calc((100% - 30px) / 3);
    -ms-grid-rows: auto 15px auto;
  }
  .understand.analytics .grid-container [data-card=methods] {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  .understand.analytics .grid-container [data-card=http-codes] {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
  .understand.analytics .grid-container [data-card=os] {
    -ms-grid-column: 5;
    -ms-grid-row: 1;
  }
  .understand.analytics .grid-container [data-card=cache-states] {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
  .understand.analytics .grid-container [data-card=response-times] {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
  }
  .understand.analytics .grid-container [data-card=response-sizes] {
    -ms-grid-column: 5;
    -ms-grid-row: 3;
  }
}
.understand.kpis .grid-container {
  -ms-grid-columns: 100%;
  -ms-grid-rows: auto 15px auto 15px auto;
}
.understand.kpis .grid-container [data-card=most] {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}
.understand.kpis .grid-container [data-card=time] {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}
.understand.kpis .grid-container [data-card=count] {
  -ms-grid-column: 1;
  -ms-grid-row: 5;
}
@media (min-width: 992px) {
  .understand.kpis .grid-container {
    -ms-grid-columns: 30% 15px calc(70% - 15px);
    -ms-grid-rows: auto 15px auto;
  }
  .understand.kpis .grid-container [data-card=most] {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
  }
  .understand.kpis .grid-container [data-card=time] {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
  .understand.kpis .grid-container [data-card=count] {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
  }
}
@supports (display: grid) {
  .understand .grid-container {
    display: grid;
    grid-gap: 15px;
  }
  .understand.analytics .grid-container {
    grid-template-columns: 100%;
  }
  @media (min-width: 992px) {
    .understand.analytics .grid-container.top-container {
      grid-template-columns: 30% calc(70% - 15px);
    }
    .understand.analytics .grid-container.pies-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .understand.kpis .grid-container {
    grid-template-columns: 100%;
    grid-template-areas: "most" "time" "count";
  }
  @media (min-width: 992px) {
    .understand.kpis .grid-container {
      grid-template-columns: 30% calc(70% - 15px);
      grid-template-areas: "most time" "most count";
    }
  }
  .understand.kpis .grid-container [data-card=most] {
    grid-area: most;
  }
  .understand.kpis .grid-container [data-card=time] {
    grid-area: time;
  }
  .understand.kpis .grid-container [data-card=count] {
    grid-area: count;
  }
}
.understand .path-container {
  display: flex;
  flex-wrap: wrap;
}
.understand .path-container span {
  padding: 2px 5px;
  background-color: #2c65ad;
  color: #ffffff;
}
.understand .path-container span.odd {
  background-color: #666666;
}

.understand.devices.statistics h2 {
  display: flex;
  align-items: center;
}
.understand.devices.statistics h2 .fas {
  margin-left: 5px;
  font-size: 60%;
}
.understand.devices.statistics .tiles-container {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.understand.devices.statistics .tiles-container .tile {
  margin: 5px;
  width: 100%;
  min-height: auto;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.understand.devices.statistics .tiles-container .tile-image {
  margin-top: 25px;
}
.understand.devices.statistics .last-users-container {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.understand.devices.statistics .last-users-container .last-user {
  display: flex;
  margin: 5px;
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.understand.devices.statistics .last-users-container .os-and-type {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 35px;
  margin-right: 5px;
  border-radius: 4px 0 0 4px;
  background-color: #3e8df3;
}
.understand.devices.statistics .last-users-container .os-and-type i {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 50px;
  color: #ffffff;
  text-align: center;
}
.understand.devices.statistics .last-users-container .os-and-type i + i {
  border-top: 1px solid #ffffff;
}
.understand.devices.statistics .last-users-container .os-and-type .fas {
  font-size: 20px;
}
.understand.devices.statistics .last-users-container .os-and-type .fab {
  font-size: 30px;
}
.understand.devices.statistics .last-users-container .device-data {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 5px 0;
  font-size: 13px;
}
.understand.devices.statistics .last-users-container time {
  color: #aeaeae;
}
.understand.devices.statistics .last-users-container .device-uid a {
  cursor: pointer;
}
.understand.devices.statistics .last-users-container time,
.understand.devices.statistics .last-users-container .device-name,
.understand.devices.statistics .last-users-container .device-model,
.understand.devices.statistics .last-users-container .device-uid {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.understand.devices.statistics .distribution-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px;
}
.understand.devices.statistics .distribution-container .chart-container {
  margin: 5px;
  padding: 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.understand.devices.statistics .details-container {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.understand.devices.statistics .details-container section {
  margin: 5px;
  width: 100%;
}
.understand.devices.statistics .details-container header {
  padding: 10px;
  background-color: #f3f3f3;
}
.understand.devices.statistics .details-container h3 {
  display: flex;
  align-items: center;
  margin: 0;
}
.understand.devices.statistics .details-container .badge {
  margin-left: auto;
}
.understand.devices.statistics .details-container ul {
  margin: 0;
  padding: 0;
  max-height: 250px;
  background-color: #ffffff;
  list-style: none;
  overflow-y: auto;
}
.understand.devices.statistics .details-container li {
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.understand.devices.statistics .details-container li + li {
  border-top: 1px solid #ccc;
}
.understand.devices.statistics .details-container .key {
  margin-right: 5px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.understand.devices.statistics .details-container .value {
  margin-left: auto;
}
@media (min-width: 768px) {
  .understand.devices.statistics .tiles-container .tile {
    width: calc(50% - 10px);
  }
  .understand.devices.statistics .last-users-container .last-user {
    width: calc(50% - 10px);
  }
  .understand.devices.statistics .details-container section {
    width: calc(50% - 10px);
  }
}
@media (min-width: 992px) {
  .understand.devices.statistics .tiles-container .tile {
    width: calc((100% / 3) - 10px);
  }
  .understand.devices.statistics .last-users-container .last-user {
    width: calc((100% / 3) - 10px);
  }
  .understand.devices.statistics .distribution-container .chart-container {
    width: calc(50% - 10px);
  }
  .understand.devices.statistics .details-container section {
    width: calc((100% / 3) - 10px);
  }
}
@media (min-width: 1200px) {
  .understand.devices.statistics .tiles-container .tile {
    width: calc((100% / 6) - 10px);
  }
  .understand.devices.statistics .last-users-container .last-user {
    width: calc((100% / 6) - 10px);
  }
  .understand.devices.statistics .details-container section {
    width: calc(20% - 10px);
  }
}

.understand.devices.console .tabs-list {
  display: flex;
  flex-wrap: wrap;
}
.understand.devices.console .tabs-list > li {
  float: none;
}
.understand.devices.console .tabs-list > li > button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.understand.devices.console .tabs-list > li:not(:last-child) > button {
  margin-right: 2px;
}
.understand.devices.console #filters-wrapper {
  background-color: #ffffff;
  border: solid #dddddd;
  border-width: 0 1px;
}
.understand.devices.console .table-container {
  border: solid #dddddd;
  border-width: 0 1px 1px 1px;
}
.understand.devices.console .table-container .table {
  margin: 0;
}
.understand.devices.console .table-container tbody .device-uid {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.understand.devices.console .first {
  cursor: pointer;
}
.understand.devices.console .even {
  background-color: #f9f9f9;
}
.understand.devices.console h3,
.understand.devices.console .h3 {
  margin-top: 0;
  color: #3b87e9;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.watch.crash-report #button-refresh {
  margin-left: auto;
}
.watch.crash-report #table-crashes .time {
  font-size: 16px;
  font-weight: bold;
}
.watch.crash-report #table-crashes .column-reason {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.watch.crash-report #table-crashes h3 {
  margin-top: 0;
  color: #3b87e9;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.watch.crash-report #table-crashes > tbody > tr.open > td {
  background-color: #3b87e9;
  color: #ffffff;
}
.watch.crash-report #table-crashes .open + tr {
  background-color: #f5f9fe;
}
.watch.crash-report .dataTables_paginate {
  text-align: center;
}
.watch.crash-report .dataTables_paginate ul.pagination {
  margin: 15px 0;
}

.margin-top-auto {
  margin-top: auto !important;
}

.margin-right-auto {
  margin-right: auto !important;
}

.margin-bottom-auto {
  margin-bottom: auto !important;
}

.margin-left-auto {
  margin-left: auto !important;
}

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.mx-1 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mt-1 {
  margin-top: 5px !important;
}

.mr-1 {
  margin-right: 5px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.mx-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.mx-3 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-3 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.mr-3 {
  margin-right: 15px !important;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.ml-3 {
  margin-left: 15px !important;
}

.m-4 {
  margin: 20px !important;
}

.mx-4 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-4 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-4 {
  margin-top: 20px !important;
}

.mr-4 {
  margin-right: 20px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

.ml-4 {
  margin-left: 20px !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.px-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.pl-1 {
  padding-left: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.px-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pr-2 {
  padding-right: 10px !important;
}

.pb-2 {
  padding-bottom: 10px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.px-3 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-3 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pt-3 {
  padding-top: 15px !important;
}

.pr-3 {
  padding-right: 15px !important;
}

.pb-3 {
  padding-bottom: 15px !important;
}

.pl-3 {
  padding-left: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.px-4 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-4 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pt-4 {
  padding-top: 20px !important;
}

.pr-4 {
  padding-right: 20px !important;
}

.pb-4 {
  padding-bottom: 20px !important;
}

.pl-4 {
  padding-left: 20px !important;
}

@media (min-width: 480px) {
  .m-xs-auto {
    margin: auto !important;
  }

  .mx-xs-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xs-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xs-auto {
    margin-top: auto !important;
  }

  .mr-xs-auto {
    margin-right: auto !important;
  }

  .mb-xs-auto {
    margin-bottom: auto !important;
  }

  .ml-xs-auto {
    margin-left: auto !important;
  }

  .m-xs-0 {
    margin: 0 !important;
  }

  .mx-xs-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-xs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-xs-0 {
    margin-top: 0 !important;
  }

  .mr-xs-0 {
    margin-right: 0 !important;
  }

  .mb-xs-0 {
    margin-bottom: 0 !important;
  }

  .ml-xs-0 {
    margin-left: 0 !important;
  }

  .m-xs-1 {
    margin: 5px !important;
  }

  .mx-xs-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-xs-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mt-xs-1 {
    margin-top: 5px !important;
  }

  .mr-xs-1 {
    margin-right: 5px !important;
  }

  .mb-xs-1 {
    margin-bottom: 5px !important;
  }

  .ml-xs-1 {
    margin-left: 5px !important;
  }

  .m-xs-2 {
    margin: 10px !important;
  }

  .mx-xs-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-xs-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mt-xs-2 {
    margin-top: 10px !important;
  }

  .mr-xs-2 {
    margin-right: 10px !important;
  }

  .mb-xs-2 {
    margin-bottom: 10px !important;
  }

  .ml-xs-2 {
    margin-left: 10px !important;
  }

  .m-xs-3 {
    margin: 15px !important;
  }

  .mx-xs-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-xs-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mt-xs-3 {
    margin-top: 15px !important;
  }

  .mr-xs-3 {
    margin-right: 15px !important;
  }

  .mb-xs-3 {
    margin-bottom: 15px !important;
  }

  .ml-xs-3 {
    margin-left: 15px !important;
  }

  .m-xs-4 {
    margin: 20px !important;
  }

  .mx-xs-4 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-xs-4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-xs-4 {
    margin-top: 20px !important;
  }

  .mr-xs-4 {
    margin-right: 20px !important;
  }

  .mb-xs-4 {
    margin-bottom: 20px !important;
  }

  .ml-xs-4 {
    margin-left: 20px !important;
  }

  .p-xs-0 {
    padding: 0 !important;
  }

  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-xs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pt-xs-0 {
    padding-top: 0 !important;
  }

  .pr-xs-0 {
    padding-right: 0 !important;
  }

  .pb-xs-0 {
    padding-bottom: 0 !important;
  }

  .pl-xs-0 {
    padding-left: 0 !important;
  }

  .p-xs-1 {
    padding: 5px !important;
  }

  .px-xs-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-xs-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pt-xs-1 {
    padding-top: 5px !important;
  }

  .pr-xs-1 {
    padding-right: 5px !important;
  }

  .pb-xs-1 {
    padding-bottom: 5px !important;
  }

  .pl-xs-1 {
    padding-left: 5px !important;
  }

  .p-xs-2 {
    padding: 10px !important;
  }

  .px-xs-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-xs-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pt-xs-2 {
    padding-top: 10px !important;
  }

  .pr-xs-2 {
    padding-right: 10px !important;
  }

  .pb-xs-2 {
    padding-bottom: 10px !important;
  }

  .pl-xs-2 {
    padding-left: 10px !important;
  }

  .p-xs-3 {
    padding: 15px !important;
  }

  .px-xs-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-xs-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pt-xs-3 {
    padding-top: 15px !important;
  }

  .pr-xs-3 {
    padding-right: 15px !important;
  }

  .pb-xs-3 {
    padding-bottom: 15px !important;
  }

  .pl-xs-3 {
    padding-left: 15px !important;
  }

  .p-xs-4 {
    padding: 20px !important;
  }

  .px-xs-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-xs-4 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-xs-4 {
    padding-top: 20px !important;
  }

  .pr-xs-4 {
    padding-right: 20px !important;
  }

  .pb-xs-4 {
    padding-bottom: 20px !important;
  }

  .pl-xs-4 {
    padding-left: 20px !important;
  }
}
@media (min-width: 768px) {
  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 5px !important;
  }

  .mx-sm-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-sm-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mt-sm-1 {
    margin-top: 5px !important;
  }

  .mr-sm-1 {
    margin-right: 5px !important;
  }

  .mb-sm-1 {
    margin-bottom: 5px !important;
  }

  .ml-sm-1 {
    margin-left: 5px !important;
  }

  .m-sm-2 {
    margin: 10px !important;
  }

  .mx-sm-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-sm-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mt-sm-2 {
    margin-top: 10px !important;
  }

  .mr-sm-2 {
    margin-right: 10px !important;
  }

  .mb-sm-2 {
    margin-bottom: 10px !important;
  }

  .ml-sm-2 {
    margin-left: 10px !important;
  }

  .m-sm-3 {
    margin: 15px !important;
  }

  .mx-sm-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-sm-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mt-sm-3 {
    margin-top: 15px !important;
  }

  .mr-sm-3 {
    margin-right: 15px !important;
  }

  .mb-sm-3 {
    margin-bottom: 15px !important;
  }

  .ml-sm-3 {
    margin-left: 15px !important;
  }

  .m-sm-4 {
    margin: 20px !important;
  }

  .mx-sm-4 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-sm-4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-sm-4 {
    margin-top: 20px !important;
  }

  .mr-sm-4 {
    margin-right: 20px !important;
  }

  .mb-sm-4 {
    margin-bottom: 20px !important;
  }

  .ml-sm-4 {
    margin-left: 20px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 5px !important;
  }

  .px-sm-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-sm-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pt-sm-1 {
    padding-top: 5px !important;
  }

  .pr-sm-1 {
    padding-right: 5px !important;
  }

  .pb-sm-1 {
    padding-bottom: 5px !important;
  }

  .pl-sm-1 {
    padding-left: 5px !important;
  }

  .p-sm-2 {
    padding: 10px !important;
  }

  .px-sm-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-sm-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pt-sm-2 {
    padding-top: 10px !important;
  }

  .pr-sm-2 {
    padding-right: 10px !important;
  }

  .pb-sm-2 {
    padding-bottom: 10px !important;
  }

  .pl-sm-2 {
    padding-left: 10px !important;
  }

  .p-sm-3 {
    padding: 15px !important;
  }

  .px-sm-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-sm-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pt-sm-3 {
    padding-top: 15px !important;
  }

  .pr-sm-3 {
    padding-right: 15px !important;
  }

  .pb-sm-3 {
    padding-bottom: 15px !important;
  }

  .pl-sm-3 {
    padding-left: 15px !important;
  }

  .p-sm-4 {
    padding: 20px !important;
  }

  .px-sm-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-sm-4 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-sm-4 {
    padding-top: 20px !important;
  }

  .pr-sm-4 {
    padding-right: 20px !important;
  }

  .pb-sm-4 {
    padding-bottom: 20px !important;
  }

  .pl-sm-4 {
    padding-left: 20px !important;
  }
}
@media (min-width: 992px) {
  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 5px !important;
  }

  .mx-md-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-md-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mt-md-1 {
    margin-top: 5px !important;
  }

  .mr-md-1 {
    margin-right: 5px !important;
  }

  .mb-md-1 {
    margin-bottom: 5px !important;
  }

  .ml-md-1 {
    margin-left: 5px !important;
  }

  .m-md-2 {
    margin: 10px !important;
  }

  .mx-md-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-md-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mt-md-2 {
    margin-top: 10px !important;
  }

  .mr-md-2 {
    margin-right: 10px !important;
  }

  .mb-md-2 {
    margin-bottom: 10px !important;
  }

  .ml-md-2 {
    margin-left: 10px !important;
  }

  .m-md-3 {
    margin: 15px !important;
  }

  .mx-md-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-md-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mt-md-3 {
    margin-top: 15px !important;
  }

  .mr-md-3 {
    margin-right: 15px !important;
  }

  .mb-md-3 {
    margin-bottom: 15px !important;
  }

  .ml-md-3 {
    margin-left: 15px !important;
  }

  .m-md-4 {
    margin: 20px !important;
  }

  .mx-md-4 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-md-4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-md-4 {
    margin-top: 20px !important;
  }

  .mr-md-4 {
    margin-right: 20px !important;
  }

  .mb-md-4 {
    margin-bottom: 20px !important;
  }

  .ml-md-4 {
    margin-left: 20px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 5px !important;
  }

  .px-md-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-md-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pt-md-1 {
    padding-top: 5px !important;
  }

  .pr-md-1 {
    padding-right: 5px !important;
  }

  .pb-md-1 {
    padding-bottom: 5px !important;
  }

  .pl-md-1 {
    padding-left: 5px !important;
  }

  .p-md-2 {
    padding: 10px !important;
  }

  .px-md-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-md-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pt-md-2 {
    padding-top: 10px !important;
  }

  .pr-md-2 {
    padding-right: 10px !important;
  }

  .pb-md-2 {
    padding-bottom: 10px !important;
  }

  .pl-md-2 {
    padding-left: 10px !important;
  }

  .p-md-3 {
    padding: 15px !important;
  }

  .px-md-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-md-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pt-md-3 {
    padding-top: 15px !important;
  }

  .pr-md-3 {
    padding-right: 15px !important;
  }

  .pb-md-3 {
    padding-bottom: 15px !important;
  }

  .pl-md-3 {
    padding-left: 15px !important;
  }

  .p-md-4 {
    padding: 20px !important;
  }

  .px-md-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-md-4 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-md-4 {
    padding-top: 20px !important;
  }

  .pr-md-4 {
    padding-right: 20px !important;
  }

  .pb-md-4 {
    padding-bottom: 20px !important;
  }

  .pl-md-4 {
    padding-left: 20px !important;
  }
}
@media (min-width: 1200px) {
  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 5px !important;
  }

  .mx-lg-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .my-lg-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mt-lg-1 {
    margin-top: 5px !important;
  }

  .mr-lg-1 {
    margin-right: 5px !important;
  }

  .mb-lg-1 {
    margin-bottom: 5px !important;
  }

  .ml-lg-1 {
    margin-left: 5px !important;
  }

  .m-lg-2 {
    margin: 10px !important;
  }

  .mx-lg-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-lg-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mt-lg-2 {
    margin-top: 10px !important;
  }

  .mr-lg-2 {
    margin-right: 10px !important;
  }

  .mb-lg-2 {
    margin-bottom: 10px !important;
  }

  .ml-lg-2 {
    margin-left: 10px !important;
  }

  .m-lg-3 {
    margin: 15px !important;
  }

  .mx-lg-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .my-lg-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mt-lg-3 {
    margin-top: 15px !important;
  }

  .mr-lg-3 {
    margin-right: 15px !important;
  }

  .mb-lg-3 {
    margin-bottom: 15px !important;
  }

  .ml-lg-3 {
    margin-left: 15px !important;
  }

  .m-lg-4 {
    margin: 20px !important;
  }

  .mx-lg-4 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-lg-4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-lg-4 {
    margin-top: 20px !important;
  }

  .mr-lg-4 {
    margin-right: 20px !important;
  }

  .mb-lg-4 {
    margin-bottom: 20px !important;
  }

  .ml-lg-4 {
    margin-left: 20px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 5px !important;
  }

  .px-lg-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .py-lg-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pt-lg-1 {
    padding-top: 5px !important;
  }

  .pr-lg-1 {
    padding-right: 5px !important;
  }

  .pb-lg-1 {
    padding-bottom: 5px !important;
  }

  .pl-lg-1 {
    padding-left: 5px !important;
  }

  .p-lg-2 {
    padding: 10px !important;
  }

  .px-lg-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-lg-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pt-lg-2 {
    padding-top: 10px !important;
  }

  .pr-lg-2 {
    padding-right: 10px !important;
  }

  .pb-lg-2 {
    padding-bottom: 10px !important;
  }

  .pl-lg-2 {
    padding-left: 10px !important;
  }

  .p-lg-3 {
    padding: 15px !important;
  }

  .px-lg-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .py-lg-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pt-lg-3 {
    padding-top: 15px !important;
  }

  .pr-lg-3 {
    padding-right: 15px !important;
  }

  .pb-lg-3 {
    padding-bottom: 15px !important;
  }

  .pl-lg-3 {
    padding-left: 15px !important;
  }

  .p-lg-4 {
    padding: 20px !important;
  }

  .px-lg-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-lg-4 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-lg-4 {
    padding-top: 20px !important;
  }

  .pr-lg-4 {
    padding-right: 20px !important;
  }

  .pb-lg-4 {
    padding-bottom: 20px !important;
  }

  .pl-lg-4 {
    padding-left: 20px !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-xs-none {
    display: none !important;
  }

  .d-xs-inline {
    display: inline !important;
  }

  .d-xs-inline-block {
    display: inline-block !important;
  }

  .d-xs-block {
    display: block !important;
  }

  .d-xs-flex {
    display: flex !important;
  }

  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
.justify-content-normal {
  justify-content: normal !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-stretch {
  justify-content: stretch !important;
}

.justify-content-left {
  justify-content: left !important;
}

.justify-content-right {
  justify-content: right !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

@media (min-width: 480px) {
  .justify-content-xs-normal {
    justify-content: normal !important;
  }

  .justify-content-xs-start {
    justify-content: flex-start !important;
  }

  .justify-content-xs-end {
    justify-content: flex-end !important;
  }

  .justify-content-xs-center {
    justify-content: center !important;
  }

  .justify-content-xs-stretch {
    justify-content: stretch !important;
  }

  .justify-content-xs-left {
    justify-content: left !important;
  }

  .justify-content-xs-right {
    justify-content: right !important;
  }

  .justify-content-xs-space-between {
    justify-content: space-between !important;
  }

  .justify-content-xs-space-around {
    justify-content: space-around !important;
  }

  .justify-content-xs-space-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 768px) {
  .justify-content-sm-normal {
    justify-content: normal !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-stretch {
    justify-content: stretch !important;
  }

  .justify-content-sm-left {
    justify-content: left !important;
  }

  .justify-content-sm-right {
    justify-content: right !important;
  }

  .justify-content-sm-space-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-space-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-space-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 992px) {
  .justify-content-md-normal {
    justify-content: normal !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-stretch {
    justify-content: stretch !important;
  }

  .justify-content-md-left {
    justify-content: left !important;
  }

  .justify-content-md-right {
    justify-content: right !important;
  }

  .justify-content-md-space-between {
    justify-content: space-between !important;
  }

  .justify-content-md-space-around {
    justify-content: space-around !important;
  }

  .justify-content-md-space-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-lg-normal {
    justify-content: normal !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-stretch {
    justify-content: stretch !important;
  }

  .justify-content-lg-left {
    justify-content: left !important;
  }

  .justify-content-lg-right {
    justify-content: right !important;
  }

  .justify-content-lg-space-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-space-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-space-evenly {
    justify-content: space-evenly !important;
  }
}
.align-items-normal {
  align-items: normal !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

@media (min-width: 480px) {
  .align-items-xs-normal {
    align-items: normal !important;
  }

  .align-items-xs-start {
    align-items: flex-start !important;
  }

  .align-items-xs-end {
    align-items: flex-end !important;
  }

  .align-items-xs-center {
    align-items: center !important;
  }

  .align-items-xs-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 768px) {
  .align-items-sm-normal {
    align-items: normal !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 992px) {
  .align-items-md-normal {
    align-items: normal !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 1200px) {
  .align-items-lg-normal {
    align-items: normal !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }
}
.flex-direction-row {
  flex-direction: row !important;
}

.flex-direction-row-reversse {
  flex-direction: row-reversse !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

@media (min-width: 480px) {
  .flex-direction-xs-row {
    flex-direction: row !important;
  }

  .flex-direction-xs-row-reversse {
    flex-direction: row-reversse !important;
  }

  .flex-direction-xs-column {
    flex-direction: column !important;
  }

  .flex-direction-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 768px) {
  .flex-direction-sm-row {
    flex-direction: row !important;
  }

  .flex-direction-sm-row-reversse {
    flex-direction: row-reversse !important;
  }

  .flex-direction-sm-column {
    flex-direction: column !important;
  }

  .flex-direction-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 992px) {
  .flex-direction-md-row {
    flex-direction: row !important;
  }

  .flex-direction-md-row-reversse {
    flex-direction: row-reversse !important;
  }

  .flex-direction-md-column {
    flex-direction: column !important;
  }

  .flex-direction-md-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 1200px) {
  .flex-direction-lg-row {
    flex-direction: row !important;
  }

  .flex-direction-lg-row-reversse {
    flex-direction: row-reversse !important;
  }

  .flex-direction-lg-column {
    flex-direction: column !important;
  }

  .flex-direction-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

@media (min-width: 480px) {
  .flex-wrap-xs-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-xs-wrap {
    flex-wrap: wrap !important;
  }

  .flex-wrap-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 768px) {
  .flex-wrap-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-wrap-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 992px) {
  .flex-wrap-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-wrap-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 1200px) {
  .flex-wrap-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-wrap-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media (min-width: 480px) {
  .flex-grow-xs-0 {
    flex-grow: 0 !important;
  }

  .flex-shrink-xs-0 {
    flex-shrink: 0 !important;
  }

  .flex-grow-xs-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-xs-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 768px) {
  .flex-grow-sm-0 {
    flex-grow: 0 !important;
  }

  .flex-shrink-sm-0 {
    flex-shrink: 0 !important;
  }

  .flex-grow-sm-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-sm-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 992px) {
  .flex-grow-md-0 {
    flex-grow: 0 !important;
  }

  .flex-shrink-md-0 {
    flex-shrink: 0 !important;
  }

  .flex-grow-md-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-md-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 1200px) {
  .flex-grow-lg-0 {
    flex-grow: 0 !important;
  }

  .flex-shrink-lg-0 {
    flex-shrink: 0 !important;
  }

  .flex-grow-lg-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-lg-1 {
    flex-shrink: 1 !important;
  }
}
.w-auto {
  width: auto !important;
}

.max-w-auto {
  max-width: auto !important;
}

.w-0 {
  width: 0 !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.max-w-25 {
  max-width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.max-w-75 {
  max-width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

@media (min-width: 480px) {
  .w-xs-auto {
    width: auto !important;
  }

  .max-w-xs-auto {
    max-width: auto !important;
  }

  .w-xs-0 {
    width: 0 !important;
  }

  .max-w-xs-0 {
    max-width: 0 !important;
  }

  .w-xs-25 {
    width: 25% !important;
  }

  .max-w-xs-25 {
    max-width: 25% !important;
  }

  .w-xs-50 {
    width: 50% !important;
  }

  .max-w-xs-50 {
    max-width: 50% !important;
  }

  .w-xs-75 {
    width: 75% !important;
  }

  .max-w-xs-75 {
    max-width: 75% !important;
  }

  .w-xs-100 {
    width: 100% !important;
  }

  .max-w-xs-100 {
    max-width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-sm-auto {
    width: auto !important;
  }

  .max-w-sm-auto {
    max-width: auto !important;
  }

  .w-sm-0 {
    width: 0 !important;
  }

  .max-w-sm-0 {
    max-width: 0 !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .max-w-sm-25 {
    max-width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .max-w-sm-50 {
    max-width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .max-w-sm-75 {
    max-width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .max-w-sm-100 {
    max-width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-md-auto {
    width: auto !important;
  }

  .max-w-md-auto {
    max-width: auto !important;
  }

  .w-md-0 {
    width: 0 !important;
  }

  .max-w-md-0 {
    max-width: 0 !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .max-w-md-25 {
    max-width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .max-w-md-50 {
    max-width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .max-w-md-75 {
    max-width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .max-w-md-100 {
    max-width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-lg-auto {
    width: auto !important;
  }

  .max-w-lg-auto {
    max-width: auto !important;
  }

  .w-lg-0 {
    width: 0 !important;
  }

  .max-w-lg-0 {
    max-width: 0 !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .max-w-lg-25 {
    max-width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .max-w-lg-50 {
    max-width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .max-w-lg-75 {
    max-width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .max-w-lg-100 {
    max-width: 100% !important;
  }
}
.text-android {
  color: #a4c639;
}

.text-ios {
  color: #9a9a9a;
}

.text-windows-phone {
  color: #0993d2;
}

.bg-android {
  background-color: #a4c639;
}

.bg-ios {
  background-color: #9a9a9a;
}

.bg-windows-phone {
  background-color: #0993d2;
}

.text-secondary {
  color: #3b87e9;
}

.bg-secondary {
  background-color: #3b87e9;
  color: #ffffff;
}

@media (min-width: 768px) {
  .flex-md-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}