.page-header {
  position: relative;

  .filters {
    display: flex;
  }

  .filter-title {
    display: inline-block;
    margin-right: 7px;
    color: $color-darker-blue;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  label.filter-title {
    margin-bottom: 0;
  }

  .filter-submit {
    height: 24px;
    padding: 0 5px;
    color: $color-darker-blue;
  }

  // Filters
  .filter {
    display: inline-flex;
  }

  // Date filter
  .filter-date {
    .input-date {
      display: inline-block;
      height: 24px;
      padding: 0 6px 0 8px;
      background-color: $color-lighter-grey;
      border: 1px solid #d8d8d8;
      border-radius: 4px;

      label {
        color: #d0d0d0;
        font-size: 12px;
        font-weight: 500;
      }

      input[type="text"] {
        width: 65px;
        height: 22px;
        margin: 0 5px;
        padding: 0;
        background: none;
        border: none;
        color: $color-darker-blue;
        font-family: $font-open-sans;
        font-size: 12px;
        font-weight: 600;
      }

      + .input-date {
        margin-left: 10px;
      }
    }
  }

  // List filter
  .filter-list {
    select.select {
      height: 24px;
    }
  }

  // Search filter
  .filter-search {
    input[type="text"],
    input[type="search"] {
      display: inline-block;
      min-width: 65px;
      height: 24px;
      padding: 0 6px 0 6px;
      background-color: $color-lighter-grey;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      color: $color-darker-blue;
      font-family: $font-open-sans;
      font-size: 12px;
      font-weight: 600;
    }
  }

  @media (max-width: $screen-xs-max) {
    .filters {
      margin-top: 10px;
      flex-direction: column;
    }

    .filter-submit {
      margin-top: 5px;
    }

    .filter {
      flex-direction: column;
    }

    .filter + .filter {
      margin-top: 5px;
    }
  }

  @media (min-width: $screen-sm-min) {
    .filters {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      align-items: center;
    }

    .filter-submit {
      margin-left: 10px;
    }

    .filter {
      align-items: center;
    }

    .filter + .filter {
      margin-left: 10px;
    }
  }
}