.error-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('/assets/images/backgrounds/logged-out.jpg') no-repeat center center;
  background-size: cover;
  color: #ffffff;
  font-family: $font-roboto-regular;

  h1 {
    color: #ffffff;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .error-code {
    font-size: 70px;
    font-family: $font-open-sans;
    text-align: center;
  }

  .error-explanation {
    text-align: center;
  }

  @media (min-width: $screen-sm-min) {
    .container {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .error-explanation {
      margin-left: 15px;
    }
  }
}